import { Box, styled } from '@mui/material';

export const FullWidthWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  backgroundColor: theme.palette.common.white,
  borderTop: '1px solid #e6e6e6',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: theme.spacing(3),
  width: '100%',
}));

export const FullWidthInnerWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  maxWidth: theme.breakpoints.values.lg,
  padding: theme.spacing(0, 3),
  [theme.breakpoints.only('xs')]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  width: '100%',
}));
