import * as React from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { Fade, Stack, Tooltip } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';

import { BodyTextBold, IconButton } from '@itp/component-library';

export const GridToolbarStatusUpdate = ({ onStatusUpdate }) => {
  const apiRef = useGridApiContext();

  const selectedRows = apiRef.current.getSelectedRows().size;
  const rowCopy = selectedRows === 1 ? 'row' : 'rows';

  const handleStatusUpdate = (e) => {
    onStatusUpdate && onStatusUpdate(e.currentTarget.value);
  };

  return (
    <Fade in={!!selectedRows}>
      <Stack alignItems="center" flexDirection="row" gap={2} pl={2}>
        <BodyTextBold noWrap>{`${selectedRows} ${rowCopy} selected`}</BodyTextBold>
        <BodyTextBold noWrap>Set status to:</BodyTextBold>
        <Stack flexDirection="row">
          <Tooltip title="Verified">
            <IconButton
              aria-label="verified"
              color="verified"
              onClick={handleStatusUpdate}
              value="verified"
            >
              <VerifiedUserOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Pending">
            <IconButton
              aria-label="pending"
              color="pending"
              onClick={handleStatusUpdate}
              value="pending"
            >
              <HistoryOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Rejected">
            <IconButton
              aria-label="rejected"
              color="rejected"
              onClick={handleStatusUpdate}
              value="rejected"
            >
              <HighlightOffOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Deleted">
            <IconButton
              aria-label="deleted"
              color="deleted"
              onClick={handleStatusUpdate}
              value="deleted"
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Fade>
  );
};
