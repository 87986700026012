import * as React from 'react';

import { Box } from '@mui/material';

import { HeadingSmall, theme } from '@itp/component-library';

interface SearchFilterPanelProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
}

export const SearchFilterPanel: React.FC<SearchFilterPanelProps> = ({
  children,
  footer,
  header,
}) => {
  return (
    <Box sx={{ mb: 3 }}>
      {header && (
        <HeadingSmall
          color={theme.palette.primary.main}
          component="p"
          mb={1}
          textTransform="capitalize"
        >
          {header}
        </HeadingSmall>
      )}
      <Box>{children}</Box>
      {footer && <Box>{footer}</Box>}
    </Box>
  );
};
