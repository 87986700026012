import React from 'react';
import { RefinementListProps, useRefinementList } from 'react-instantsearch';

import { Box, Checkbox, FormControlLabel, List, ListItem, Stack } from '@mui/material';

import {
  BodyText,
  BodyTextBold,
  ButtonSecondary,
  SearchFilterPanel,
  theme,
} from '@itp/component-library';

type SearchRefinementListProps = {
  header?: string;
} & RefinementListProps;

export const SearchRefinementList: React.FC<SearchRefinementListProps> = ({ header, ...props }) => {
  const { canToggleShowMore, isShowingMore, items, refine, toggleShowMore } =
    useRefinementList(props);

  if (items.filter((item) => item.label).length === 0) {
    return null;
  }

  return (
    <SearchFilterPanel header={header || props.attribute}>
      <List sx={{ p: 0 }}>
        {items.map((item) => (
          <React.Fragment key={item.value}>
            {item?.label && (
              <ListItem sx={{ p: 0 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        margin: theme.spacing(0, 1, 0.5, 0),
                        padding: 0,
                      }}
                      checked={item.isRefined}
                      onChange={() => refine(item.value)}
                      value={item.value}
                    />
                  }
                  label={
                    <Stack flexDirection="row">
                      {item.isRefined ? (
                        <BodyTextBold
                          component="p"
                          maxWidth="120px"
                          noWrap
                          textTransform="capitalize"
                        >
                          {item.label}
                        </BodyTextBold>
                      ) : (
                        <BodyText component="p" maxWidth="120px" noWrap textTransform="capitalize">
                          {item.label}
                        </BodyText>
                      )}
                      <Box>
                        <span className="ais-RefinementList-count">{item.count}</span>
                      </Box>
                    </Stack>
                  }
                  sx={{ m: 0 }}
                />
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>
      {props.showMore && canToggleShowMore && (
        <ButtonSecondary
          aria-label="TS Filter Toggle Show More"
          disabled={!canToggleShowMore}
          onClick={toggleShowMore}
          size="small"
          sx={{ mt: 2 }}
        >
          {isShowingMore ? 'Show less' : 'Show more'}
        </ButtonSecondary>
      )}
    </SearchFilterPanel>
  );
};
