import * as React from 'react';

import { useRouter } from 'next/router';

export const useBeforeUnload = (unsavedChanges: boolean, warningText: string) => {
  const router = useRouter();

  React.useEffect(() => {
    const handleWindowClose = (e) => {
      if (!unsavedChanges) return null;
      e.preventDefault();
      return (e.returnValue = warningText);
    };
    const handleBrowseAway = () => {
      if (!unsavedChanges) return;
      if (window.confirm(warningText)) return;
      router.events.emit('routeChangeError');
      throw 'routeChange aborted.';
    };
    window.addEventListener('beforeunload', handleWindowClose);
    router.events.on('routeChangeStart', handleBrowseAway);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
      router.events.off('routeChangeStart', handleBrowseAway);
    };
  }, [unsavedChanges, warningText, router?.events]);
};
