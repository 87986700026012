import * as React from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';

import { LogoIcon, theme } from '@itp/component-library';

import { HomeLink, StyledToolbar, TopBarWrapper } from './topBar.styles';

interface TopBarProps {
  handleDrawerToggle: () => void;
}

export const TopBar: React.FC<TopBarProps> = ({ handleDrawerToggle }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!isMobile) {
    return null;
  }

  return (
    <TopBarWrapper>
      <AppBar
        color="transparent"
        elevation={0}
        position="static"
        sx={{ marginBottom: '2px', maxWidth: 'sm' }}
      >
        <StyledToolbar disableGutters>
          <IconButton
            aria-label="open drawer"
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 2, mr: -2 }}
          >
            <MenuIcon />
          </IconButton>
          <HomeLink href="/">
            <LogoIcon />
          </HomeLink>
        </StyledToolbar>
      </AppBar>
    </TopBarWrapper>
  );
};
