import dayjs from 'dayjs';

const locales = require('dayjs/locale');

const dayjsLocales = locales.map((l) => l.key);

const mapper = new Map([
  ['en-us', 'en'],
  ['no', 'nb'],
]);

const fix = (l: string): string => (mapper.has(l) ? mapper.get(l)! : l);

const contains = (l: string): Boolean => dayjsLocales.includes(l);

async function load(locale: string) {
  if (locale === 'en') return;

  await import(`dayjs/locale/${locale}` + '.js');
  dayjs.locale(locale);
}

export const getMyLocales = () => {
  let myLocales: any[];

  if (typeof window !== 'undefined') {
    myLocales = navigator.languages.map((l) => l.toLocaleLowerCase());

    myLocales.some((locale) => {
      locale = locale.toLowerCase();
      locale = fix(locale);

      if (contains(locale)) {
        load(locale);
        return true;
      } else {
        const [langTag] = locale.split('-');

        if (contains(langTag)) {
          const l = fix(langTag);
          load(l);
          return true;
        }
      }

      return false;
    });
  }
  return myLocales;
};
