import { useState } from 'react';

import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';

import { HeadingMedium } from '@itp/component-library';

const settings = [
  {
    description: 'Use Google Authenticator or similar',
    name: 'TOTP',
  },
  {
    description: 'Use Auth0 Guardian to receive push notifications (coming soon)',
    name: 'PUSH',
  },
  {
    description: 'Use SMS sent to your mobile phone (insecure)',
    name: 'SMS',
  },
  {
    description: 'Get a phone call to your mobile or landline phone (insecure)',
    name: 'VOICE',
  },
];

export function AddAuthenticatorsItem({ onSelect }: any) {
  const [selected, setSelected] = useState(settings[0]);

  const handleOnSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = settings.find((setting) => setting.name === event.target.value);
    if (selectedOption) {
      setSelected(selectedOption);
      onSelect(selectedOption);
    }
  };

  return (
    <Stack className="mt-4" pt={4}>
      <HeadingMedium className="sr-only">Authenticator type</HeadingMedium>
      <RadioGroup onChange={handleOnSelect} sx={{ mt: 1, pl: 2 }} value={selected.name}>
        {settings.map((setting, i) => (
          <FormControlLabel
            control={<Radio />}
            disabled={i > 0}
            key={setting.name}
            label={setting.description}
            value={setting.name}
          />
        ))}
      </RadioGroup>
    </Stack>
  );
}
