import * as React from 'react';

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Stack } from '@mui/material';
import { DataGrid, DataGridProps, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid';

import { IconButton, Link } from '@itp/component-library';

import { GridToolbarActions, GridToolbarQuickFilter, GridToolbarStatusUpdate } from './components';
import { StyledGridToolbarContainer } from './dataTable.styles';

export type DataTableProps = DataGridProps &
  (
    | {
        hasStatusUpdateToolbar: true;
        onStatusUpdate: (value: any) => void;
      }
    | { hasStatusUpdateToolbar?: never; onStatusUpdate?: never }
  ) & {
    addButtonText?: string;
    hasActionsToolbar?: boolean;
    hasOpenInNewTabIcon?: boolean;
    hasQuickFilter?: boolean;
    onAdd?: () => void;
    quickFilterDefaultValue?: any;
    quickFilterInputName?: string;
  };

export type DataRowsCols = {
  cols: DataTableProps['columns'];
  rows: DataTableProps['rows'];
};

export const PAGE_SIZE = 10;
const PAGE_SIZES = [PAGE_SIZE, 20, 50, 100];

const CustomToolbar = ({
  addButtonText,
  apiRef,
  hasActionsToolbar,
  hasQuickFilter,
  hasStatusUpdateToolbar,
  onAdd,
  quickFilterProps,
  statusUpdateProps,
}) => {
  const hasExtraTolbar = hasStatusUpdateToolbar || hasActionsToolbar;
  return (
    <>
      {hasQuickFilter && (
        <StyledGridToolbarContainer>
          <GridToolbarQuickFilter apiRef={apiRef} {...quickFilterProps} />
        </StyledGridToolbarContainer>
      )}
      {hasExtraTolbar && (
        <Stack flexDirection="row" mt={5}>
          {hasStatusUpdateToolbar && <GridToolbarStatusUpdate {...statusUpdateProps} />}
          {hasActionsToolbar && <GridToolbarActions addButtonText={addButtonText} onAdd={onAdd} />}
        </Stack>
      )}
    </>
  );
};

export const DataTable: React.FC<DataTableProps> = ({
  addButtonText,
  columns,
  hasActionsToolbar,
  hasOpenInNewTabIcon,
  hasQuickFilter = true,
  hasStatusUpdateToolbar,
  initialState,
  onAdd,
  onStatusUpdate,
  quickFilterDefaultValue,
  quickFilterInputName = 'quickFilterValues',
  rowHeight = 54,
  rows,
  ...props
}) => {
  const apiRef = useGridApiRef();

  const pageSizeOptions = PAGE_SIZES.filter((size) =>
    props.paginationMode === 'server' && props.rowCount
      ? size <= props.rowCount
      : rows.length
      ? size <= rows.length
      : true,
  );

  const newColumns = [
    ...(hasOpenInNewTabIcon
      ? [
          {
            field: 'openInNewTab',
            headerName: '',
            renderCell: (params: GridRenderCellParams) => (
              <IconButton
                aria-label="open in new tab"
                component={Link}
                href={params?.row?.url || '/'}
                onClick={(e) => e.stopPropagation()}
                size="small"
                target="_blank"
                title="open in new tab"
              >
                <OpenInNewOutlinedIcon />
              </IconButton>
            ),
            sortable: false,
            width: 40,
          },
        ]
      : []),
    ...columns,
  ];

  const rowCountRef = React.useRef(props.rowCount || 0);

  const serverRowCount = React.useMemo(() => {
    if (props.rowCount !== undefined) {
      rowCountRef.current = props.rowCount;
    }
    return rowCountRef.current;
  }, [props.rowCount]);

  const rowCount = props.paginationMode === 'server' ? serverRowCount : rows.length;

  return (
    <DataGrid
      hideFooter={
        props.paginationMode === 'server'
          ? props.rowCount <= props.paginationModel.pageSize
          : rows.length <= PAGE_SIZE
      }
      apiRef={apiRef}
      autoHeight
      columns={newColumns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableDensitySelector
      rowHeight={rowHeight}
      rows={rows}
      {...props}
      initialState={{
        ...initialState,
        pagination: {
          paginationModel: {
            page: 0,
            pageSize: PAGE_SIZE,
          },
        },
      }}
      slotProps={{
        baseCheckbox: {
          name: 'checkbox',
        },
        toolbar: {
          addButtonText,
          apiRef,
          hasActionsToolbar,
          hasQuickFilter,
          hasStatusUpdateToolbar,
          onAdd,
          quickFilterProps: {
            name: quickFilterInputName,
            value: quickFilterDefaultValue,
          },
          statusUpdateProps: {
            onStatusUpdate,
          },
        },
      }}
      slots={{
        noRowsOverlay: () => (
          <Stack alignItems="center" height="100%" justifyContent="center">
            No data
          </Stack>
        ),
        toolbar: CustomToolbar,
      }}
      sx={{
        '& .MuiDataGrid-row:hover': {
          backgroundColor: 'rgba(25, 118, 210, 0.1)',
          cursor: 'pointer',
        },
        position: 'unset',
      }}
      onPaginationModelChange={props.onPaginationModelChange}
      pageSizeOptions={pageSizeOptions}
      paginationModel={props.paginationModel}
      rowCount={rowCount}
    />
  );
};
