import * as React from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

export const SharedHeaders: React.FC = () => {
  const router = useRouter();
  return (
    <Head>
      <meta
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        name="viewport"
      />
      <link crossOrigin="use-credentials" href="/manifest.json" rel="manifest" />
      <title>My Account</title>
    </Head>
  );
};
