import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, Rating as MuiRating, RatingProps as MuiRatingProps, Stack } from '@mui/material';

import { BodyTextSmall, SKILL_LEVELS } from '@itp/component-library';

type RatingProps = {} & MuiRatingProps;

export const Rating: React.FC<RatingProps> = ({ name = 'ratingField', ...props }) => {
  const {
    control,
    formState: { defaultValues },
  } = useFormContext();

  const [hover, setHover] = React.useState(-1);

  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <Stack alignItems="center" flexDirection="row">
          <MuiRating
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
            defaultValue={props.defaultValue || defaultValues?.[name]}
            max={5}
            name={name}
            onChange={onChange}
            size={props.size}
            value={Number(value) || props.defaultValue || defaultValues?.[name]}
          />

          {value !== null && (
            <Box sx={{ ml: 2 }}>
              <BodyTextSmall sx={{ lineHeight: '10px' }}>
                {SKILL_LEVELS[hover !== -1 ? hover : value]}
              </BodyTextSmall>
            </Box>
          )}
        </Stack>
      )}
      control={control}
      defaultValue={defaultValues?.[name]}
      name={name}
    />
  );
};
