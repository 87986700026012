import * as React from 'react';

import { v4 as uuid } from 'uuid';

import { AlertProps } from '@mui/material';

interface NotificationsContextProps {
  children?: React.ReactNode;
}

export interface Notification {
  action?: {
    label: string;
    onClick: () => void;
  };
  content: React.ReactNode;
  id?: string;
  type: AlertProps['severity'];
}

export interface NotificationsContextValues {
  addNotification: (notification: Notification) => void;
  notifications: [] | Notification[];
  removeNotification: (id?: string) => void;
}

export const NotificationsContext = React.createContext<NotificationsContextValues>({
  addNotification: () => {},
  notifications: [],
  removeNotification: () => {},
});

export const NotificationsProvider: React.FC<NotificationsContextProps> = ({ children }) => {
  const [notifications, setNotifications] = React.useState<[] | Notification[]>([]);

  const addNotification = React.useCallback((notification: Notification) => {
    const id = notification?.id ?? uuid();
    setNotifications((prev) => [{ ...notification, id }, ...prev]);
    return id;
  }, []);

  const removeNotification = React.useCallback((id?: string) => {
    id
      ? setNotifications((prev) => prev.filter((item) => item['id'] !== id))
      : setNotifications([]);
  }, []);

  const value: NotificationsContextValues = { addNotification, notifications, removeNotification };

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};
