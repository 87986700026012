import * as React from 'react';

import { useAuth0, User } from '@auth0/auth0-react';

type UserContextProps = {
  children?: React.ReactNode;
};

export type UserContextValues = {
  setUser: React.Dispatch<React.SetStateAction<User>>;
  user?: User;
};

export const UserContext = React.createContext<UserContextValues>({ setUser: () => {} });

export const UserProvider: React.FC<UserContextProps> = ({ children }) => {
  const { user: userAuth } = useAuth0();

  const [user, setUser] = React.useState<User>({});

  React.useEffect(() => {
    userAuth && setUser(userAuth);
  }, [userAuth]);

  const value: UserContextValues = { setUser, user };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider`);
  }
  return context;
};
