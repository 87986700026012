import { Drawer, ListItemIcon, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

import { Link, theme } from '@itp/component-library';

const drawerWidth = 240;
const drawerWidthSmall = 80;

export const StyledMenuItem = styled(MenuItem)<{ active?: boolean }>(({ active, theme }) => ({
  '&:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.1)',
  },
  color: active ? '#8eebff' : 'black',
  paddingLeft: theme.spacing(2),
}));

export const StyledListItemIcon = styled(ListItemIcon)<{ active?: boolean }>(({ active }) => ({
  '&&&': {
    color: active ? '#8eebff' : 'black',
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '0 auto',
      minWidth: 'auto',
    },
  },
}));

export const HomeLink = styled(Link)(({ theme }) => ({
  '& svg': {
    marginTop: theme.spacing(1),
    [theme.breakpoints.between('sm', 'md')]: {
      height: '25px',
      width: '45px',
    },
    width: '97px',
  },
  margin: theme.spacing(2, 'auto'),
}));

export const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    [theme.breakpoints.between('sm', 'md')]: {
      width: drawerWidthSmall,
    },
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
    width: drawerWidth,
  },
  flexShrink: 0,
  height: '100vh',
  [theme.breakpoints.between('sm', 'md')]: {
    width: drawerWidthSmall,
  },
  width: drawerWidth,
}));
