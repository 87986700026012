import { useRouter } from 'next/router';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useAuthenticator, useEnrollment } from '../../hooks/mfa';
import {
  BodyText,
  ButtonPrimary,
  ButtonTextSecondary,
  Card,
  Form,
  HeadingLarge,
  TextField,
} from '@itp/component-library';
import { Stack } from '@mui/material';

export function SMSEnrollment() {
  const phoneInputEl = useRef<HTMLInputElement>();
  const bindingCodeInputEl = useRef<HTMLInputElement>();

  const [canContinue, setCanContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const router = useRouter();

  const { enrollWithSMS, confirmSMS } = useEnrollment();
  const { deleteInActiveAuthenticators } = useAuthenticator();

  useEffect(() => {
    function setIsEnrollingToFalse(e: any) {
      localStorage.setItem('isEnrolling', 'false');
    }

    window.addEventListener('beforeunload', setIsEnrollingToFalse);

    return () => window.removeEventListener('beforeunload', setIsEnrollingToFalse);
  }, []);

  useEffect(() => {
    function setIsEnrollingToFalse(e: any) {
      localStorage.setItem('isEnrolling', 'false');
    }

    window.addEventListener('popstate', setIsEnrollingToFalse);

    return () => window.removeEventListener('popstate', setIsEnrollingToFalse);
  }, []);

  const cancelEnrollment = async () => {
    await deleteInActiveAuthenticators();
    router.push('/security');
  };

  const enroll = async () => {
    if (!phoneInputEl?.current?.value) {
      return;
    }

    const phone = phoneInputEl.current.value;

    setLoading(true);
    const res = await enrollWithSMS(phone);
    setLoading(false);

    if (res.status === 200) {
      setCanContinue(true);
    } else {
      const { error_description } = await res.json();
      enqueueSnackbar(error_description);
      setError(true);
      setCanContinue(false);
    }
  };

  const confirmEnrollment = async () => {
    if (!bindingCodeInputEl?.current?.value) {
      return;
    }
    const binding_code = bindingCodeInputEl.current.value;

    setLoading(true);
    const res = await confirmSMS(binding_code);
    setLoading(false);

    if (res.status === 200) {
      setSuccess(true);

      enqueueSnackbar('Success');

      setTimeout(() => {
        enqueueSnackbar('Redirecting');
      }, 2000);

      setTimeout(() => {
        router.push(`/security`);
      }, 3000);
    } else {
      const { error_description } = await res.json();
      enqueueSnackbar(error_description, { variant: 'error' });
      setError(true);
    }
  };

  return (
    <Card>
      <Card.Header>
        <HeadingLarge>Secure Your Account</HeadingLarge>
      </Card.Header>
      <Card.Content>
        <BodyText>
          Enter your country code and phone number to which we can send a 6-digit code. Then enter
          your recieved One-time Password.
        </BodyText>

        <Stack alignSelf="center" margin="auto" maxWidth="300px">
          <Form debug={true} id="otp" values={{ binding_code: '' }} onSubmit={confirmEnrollment}>
            {canContinue ? (
              <SMSForm
                inputEl={bindingCodeInputEl}
                onClick={confirmEnrollment}
                loading={loading}
                success={success}
                error={error}
              />
            ) : (
              <PhoneNumberForm
                inputEl={phoneInputEl}
                onClick={enroll}
                loading={loading}
                success={success}
                error={error}
              />
            )}
            <div className="w-full">
              <ButtonTextSecondary
                type="reset"
                onClick={cancelEnrollment}
                style={{ width: '100%', marginTop: 24, background: '#DC2626' }}
              >
                Cancel enrollment
              </ButtonTextSecondary>
            </div>
          </Form>
        </Stack>
      </Card.Content>
    </Card>
  );
}

const PhoneNumberForm = ({ onChange, inputEl, onClick, loading, success, error }: any) => {
  return (
    <div className="w-full mt-4">
      <TextField
        id="phone-number-input"
        onChange={onChange}
        inputRef={inputEl}
        autoFocus
        placeholder="+1234567890"
      />
      <div className="w-full mt-4">
        <ButtonPrimary loading={loading} onClick={onClick}>
          Continue
        </ButtonPrimary>
      </div>
    </div>
  );
};

const SMSForm = ({ loading, onChange, inputEl, onClick, success, error }: any) => {
  return (
    <div className="w-full mt-4">
      <TextField
        id="sms-input"
        onChange={onChange}
        placeholder="XXXXXX"
        inputRef={inputEl}
        autoFocus
      />
      <div className="w-full mt-4">
        <ButtonPrimary loading={loading} onClick={onClick}>
          Enroll
        </ButtonPrimary>
      </div>
    </div>
  );
};
