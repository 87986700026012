import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { ListItemText, Menu } from '@mui/material';

import { Avatar, BodyTextBold, BodyTextSmall, theme } from '@itp/component-library';
import { useUser } from '@itp/myaccount';

import { StyledListItemIcon, StyledMenuItem } from './asideNavigation.styles';
export const ProfileMenu = () => {
  const { user } = useUser();
  const { logout } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const openProfileMenu = Boolean(!!anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledMenuItem onClick={handleProfileMenuOpen} sx={{ py: 2 }}>
        <StyledListItemIcon sx={{ ml: '-5px' }}>
          <Avatar alt={user?.name} size="small">
            <BodyTextSmall>{user?.name?.charAt(0)}</BodyTextSmall>
          </Avatar>
        </StyledListItemIcon>
        <ListItemText sx={{ ml: 1, [theme.breakpoints.between('sm', 'md')]: { display: 'none' } }}>
          <BodyTextBold>{user?.name}</BodyTextBold>
        </ListItemText>
      </StyledMenuItem>

      <Menu
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={anchorEl}
        id="profile-menu"
        MenuListProps={{ sx: { minWidth: '205px', py: 0 } }}
        onClose={handleProfileMenuClose}
        open={openProfileMenu}
      >
        <StyledMenuItem
          onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
        >
          <StyledListItemIcon>
            <LogoutRoundedIcon />
          </StyledListItemIcon>
          <ListItemText>
            <BodyTextBold>Log out</BodyTextBold>
          </ListItemText>
        </StyledMenuItem>
      </Menu>
    </>
  );
};
