import React, { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@mui/material';

import { Card, HeadingLarge } from '@itp/component-library';

import AuthenticatorList from './components/mfa';
import { Password } from './components/password';

export const AccountPage = () => {
  const { isAuthenticated, user } = useAuth0();
  const router = useRouter();

  useEffect(() => {
    if (!isAuthenticated) router.push('/');
  }, [isAuthenticated, router]);

  return (
    <>
      <Card>
        <Card.Header>
          <HeadingLarge>Account details</HeadingLarge>
        </Card.Header>
        <Card.Content sx={{ pt: 0 }}>
          <Stack display="flex" flexDirection="row" mb={2}>
            Name: {user?.name}
          </Stack>
          <Stack display="flex" flexDirection="row" mb={2}>
            Email: {user?.email}
          </Stack>
        </Card.Content>
      </Card>

      <Password></Password>

      <Card>
        <Card.Header>
          <HeadingLarge>Multi-factor authentication</HeadingLarge>
        </Card.Header>
        <Card.Content sx={{ pt: 0 }}>
          <AuthenticatorList></AuthenticatorList>
        </Card.Content>
      </Card>
    </>
  );
};
