import { useCallback, useEffect, useRef, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Stack } from '@mui/material';

import { BodyText } from '@itp/component-library';
import { useAuthenticator } from '@itp/myaccount';

import { AddAuthenticators } from './AddAuthenticators';
import { AuthenticatorItem } from './AuthenticatorItem';

type AuthenticatorType = {
  active: boolean;
  authenticator_type: string;
  id: string;
};

export default function AuthenticatorList() {
  const { isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [authenticators, setAuthenticators] = useState<AuthenticatorType[]>([]);
  const { getAuthenticators } = useAuthenticator();
  const mountedRef: any = useRef(true);

  const loadAuthenticators = useCallback(async () => {
    if (mountedRef.current) {
      setLoading(true);
      const authenticators = await getAuthenticators();
      setAuthenticators(authenticators);

      setLoading(false);
    }
  }, [getAuthenticators]);

  useEffect(() => {
    loadAuthenticators();
    return () => {
      mountedRef.current = false;
    };
  }, [loadAuthenticators]);

  return (
    <div className="flex items-center mb-32">
      <div className="w-full">
        <Stack>
          <BodyText>Currently active authenticators</BodyText>
        </Stack>

        <div className="mt-8">
          {loading || !isAuthenticated || !Array.isArray(authenticators) ? (
            <Stack alignItems="center" p={4}>
              <CircularProgress size={48} />
            </Stack>
          ) : (
            authenticators
              .filter((authenticator) => authenticator.active)
              .map((authenticator, i) => (
                <AuthenticatorItem
                  authenticator={authenticator}
                  key={i}
                  setAuthenticators={setAuthenticators}
                />
              ))
          )}
        </div>
        <AddAuthenticators />
      </div>
    </div>
  );
}
