import { Box, styled, Typography } from '@mui/material';

export const NotFound = styled(Box)(({ theme }) => ({
  left: '50%',
  position: 'absolute',
  top: 0,
  transform: 'translateX(-50%)',
  zIndex: -1,
}));

export const NotFound404 = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[200],
  fontSize: 150,
  fontWeight: 900,
  left: '50%',
  letterSpacing: 1,
  position: 'absolute',
  [theme.breakpoints.up('sm')]: {
    fontSize: 240,
  },
  top: '50%',
  transform: 'translate(-50%, -40%)',
}));
