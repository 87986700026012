import * as React from 'react';

import { CardHeader, CardHeaderProps, Typography } from '@mui/material';

export interface HeaderProps extends CardHeaderProps {
  children: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({ children, ...props }) => {
  return (
    <CardHeader
      title={
        <Typography align="left" component="div" variant="headingMedium">
          {children}
        </Typography>
      }
      {...props}
    />
  );
};
