import React from 'react';

import { Box } from '@mui/material';

import {
  Card,
  ComingSoonIllustration,
  HeadingLarge,
  HeadingSmall,
  theme,
} from '@itp/component-library';

export const ComingSoon = () => {
  return (
    <Card>
      <Card.Header>
        <Box textAlign="center">
          <HeadingLarge color={theme.palette.secondary.main} component="p" fontWeight="400">
            We are building our website
          </HeadingLarge>
          <HeadingSmall fontWeight="400">
            We are currently working on this function. Please come back later...
          </HeadingSmall>
        </Box>
      </Card.Header>
      <Card.Content>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(5),
          }}
        >
          <ComingSoonIllustration />
        </Box>
      </Card.Content>
    </Card>
  );
};
