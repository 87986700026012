import * as React from 'react';

import { CardContent, CardContentProps } from '@mui/material';

export interface ContentProps extends CardContentProps {
  children: React.ReactNode;
}

export const Content: React.FC<ContentProps> = ({ children, ...props }) => {
  return <CardContent {...props}>{children}</CardContent>;
};
