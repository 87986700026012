import { useAuth0 } from '@auth0/auth0-react';

import { fetchAPI } from '@itp/component-library';

import type { Auth0Error } from './types';

import { AUTH_DOMAIN, ORIGINAL_DOMAIN } from '../../constants';

export const ORIGINAL_DOMAIN_AUTH_AUDIENCE = `https://${ORIGINAL_DOMAIN}/mfa/`;

export function useAuthenticator() {
  const auth = useAuth0();

  const getAuthenticators = async () => {
    return fetchAPI(
      {
        method: 'GET',
        url: `https://${AUTH_DOMAIN}/mfa/authenticators`,
      },
      {
        auth,
      },
    );
  };

  const deleteAuthenticator = async (id: string) => {
    return fetchAPI(
      {
        method: 'DELETE',
        url: `https://${AUTH_DOMAIN}/mfa/authenticators/${id}`,
      },
      {
        auth,
      },
    );
  };

  const deleteInActiveAuthenticators = async () => {
    let status = 204;
    let errors: Auth0Error[] = [];

    const authenticators = await getAuthenticators();

    for (const authenticator of authenticators) {
      const { active, id } = authenticator;
      if (!active) {
        const res = await deleteAuthenticator(id);
        if (res.status !== 204) {
          status = res.status;
          const { error, error_description } = res;
          errors.push({
            error,
            error_description,
          });
        }
      }
    }

    return {
      errors,
      status,
    };
  };

  return {
    deleteAuthenticator,
    deleteInActiveAuthenticators,
    getAuthenticators,
  };
}
