import React from 'react';

import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { IconButton, Popover, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { ButtonTextPrimary, isDate } from '@itp/component-library';

export const DateRangeFilter = ({ column }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  React.useEffect(() => {
    if (startDate && endDate) {
      const newFilterValue = [startDate, endDate];
      if (JSON.stringify(column.getFilterValue()) !== JSON.stringify(newFilterValue)) {
        column.setFilterValue(newFilterValue);
      }
    }
  }, [startDate, endDate, column]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResetClick = () => {
    setAnchorEl(null);
    setStartDate(null);
    setEndDate(null);
    column.setFilterValue(undefined);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'dateRangeFilter' : undefined;

  const buttonText =
    startDate && endDate ? `${isDate(startDate)} - ${isDate(endDate)}` : 'All Time';

  return (
    <>
      <Stack direction="row">
        <ButtonTextPrimary
          aria-describedby={id}
          onClick={handleClick}
          startIcon={<CalendarMonthRoundedIcon sx={{ height: '20px', mt: '-3px' }} />}
          sx={{ '&&& .MuiButton-startIcon': { mr: '6px' } }}
        >
          {buttonText}
        </ButtonTextPrimary>

        {startDate && endDate && (
          <IconButton
            disableRipple
            disableTouchRipple
            onClick={handleResetClick}
            size="small"
            sx={{ p: 0 }}
          >
            <ClearRoundedIcon />
          </IconButton>
        )}
      </Stack>
      <Popover
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={anchorEl}
        id={id}
        onClose={handleClose}
        open={open}
      >
        <Stack direction="row" m={2} spacing={1}>
          <DatePicker
            label="Start Date"
            maxDate={endDate}
            onChange={(newValue) => setStartDate(newValue)}
            value={startDate}
          />
          <DatePicker
            label="End Date"
            minDate={startDate}
            onChange={(newValue) => setEndDate(newValue)}
            value={endDate}
          />
        </Stack>
      </Popover>
    </>
  );
};
