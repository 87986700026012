import React from 'react';

import {
  MaterialReactTable,
  type MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  type MRT_Row,
  type MRT_RowData,
  type MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table';

import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import { Box, Stack } from '@mui/material';

import { ButtonSecondary } from '../button';
import { HeadingMedium } from '../typography';
import { DateRangeFilter } from './components/dateRangeFilter';

interface Props<TData extends MRT_RowData> extends MRT_TableOptions<TData> {
  addNewButtonText?: string;
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  dateRangeFilterColumnId?: string;
  onAdd?: () => void;
  onRowClick?: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: MRT_Row<TData>,
  ) => void;
  title?: string;
}

const enhanceColumnsWithDateFilter = (columns, dateRangeFilterColumnId: string) => {
  return columns.map((column) => {
    if (column.id === dateRangeFilterColumnId) {
      return {
        ...column,
        Filter: ({ column }) => <DateRangeFilter column={column} />,
        filterFn: 'dateRange',
      };
    }
    return column;
  });
};

export const MRTDataTable = <TData extends MRT_RowData>({
  addNewButtonText = 'Add',
  columns,
  data,
  dateRangeFilterColumnId,
  onAdd,
  onRowClick,
  title,
  ...rest
}: Props<TData>) => {
  const table = useMaterialReactTable({
    columns: dateRangeFilterColumnId
      ? enhanceColumnsWithDateFilter(columns, dateRangeFilterColumnId)
      : columns,
    data,
    displayColumnDefOptions: {
      'mrt-row-expand': {
        header: '',
        maxSize: 0,
        muiTableBodyCellProps: {
          sx: {
            padding: 0,
          },
        },
        muiTableHeadCellProps: {
          sx: {
            padding: 0,
          },
        },
        size: 0,
      },
    },
    enableColumnActions: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableExpandAll: false,
    enableGlobalFilter: true,
    enableGlobalFilterRankedResults: false,
    enableToolbarInternalActions: false,
    filterFns: {
      dateRange: (row, columnId, filterValue) => {
        const cellValue = new Date(row.getValue(columnId));
        const [start, end] = filterValue;
        return cellValue >= start && cellValue <= end;
      },
    },
    initialState: { showGlobalFilter: true },
    manualFiltering: false, //turn off built-in client-side filtering
    manualPagination: false, //turn off built-in client-side pagination
    manualSorting: false, //turn off built-in client-side sorting
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.common.white,
    }),
    muiBottomToolbarProps: {
      sx: {
        boxShadow: 'none',
      },
    },
    muiDetailPanelProps: {
      sx: {
        padding: 0,
      },
    },
    muiPaginationProps: {
      showRowsPerPage: false,
    },
    muiSearchTextFieldProps: {
      InputProps: {
        sx: {
          borderRadius: '20px',
          fontSize: '12px',
          minWidth: '220px',
        },
      },
      placeholder: 'Search...',
      variant: 'outlined',
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        event.stopPropagation();
        onRowClick && onRowClick(event, row);
      },
      sx: {
        '&:hover': {
          '& td:after': {
            backgroundColor: 'rgba(25, 118, 210, 0.1)',
          },
          cursor: 'pointer',
        },
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        fontWeight: 500,
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        border: 'none',
      },
    },
    positionExpandColumn: 'last',
    renderTopToolbar: ({ table }) => (
      <Stack
        flexDirection={'row'}
        sx={{ justifyContent: title ? 'space-between' : 'right', mb: 5 }}
      >
        {title && <HeadingMedium>{title}</HeadingMedium>}

        <Stack alignItems={'center'} flexDirection={'row'} gap={2}>
          {dateRangeFilterColumnId && (
            <DateRangeFilter column={table.getColumn(dateRangeFilterColumnId)} />
          )}
          <MRT_GlobalFilterTextField table={table} />
          {onAdd ? (
            <Box>
              <ButtonSecondary
                onClick={onAdd}
                size="small"
                startIcon={<PlaylistAddOutlinedIcon />}
                sx={{ height: '33px' }}
              >
                {addNewButtonText}
              </ButtonSecondary>
            </Box>
          ) : null}
        </Stack>
      </Stack>
    ),
    ...rest,
  });

  return <MaterialReactTable table={table} />;
};
