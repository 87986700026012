import React from 'react';
import { usePagination } from 'react-instantsearch';

import { Pagination } from '@mui/material';

export const SearchPagination = () => {
  const { currentRefinement, nbPages, refine } = usePagination();

  const pageIndex = currentRefinement + 1;

  if (nbPages <= 1) {
    return null;
  }

  return (
    <Pagination
      count={nbPages}
      onChange={(e, value) => refine(value - 1)}
      page={pageIndex}
      showFirstButton
      showLastButton
      size="small"
      variant="text"
    />
  );
};
