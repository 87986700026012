export default function imageLoader({
  quality,
  src,
  width,
}: {
  quality: any;
  src: string;
  width: any;
}) {
  return `${src}?w=${width}&q=${quality || 75}`;
}
