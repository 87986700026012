import * as React from 'react';
import { FormProvider, useForm, ValidationMode } from 'react-hook-form';

import { enqueueSnackbar } from 'notistack';
import { z } from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';

import { ButtonPrimary, ButtonTextSecondary, theme } from '@itp/component-library';

export interface FormProps {
  actionButtonsLeft?: boolean;
  buttonProps?: any;
  buttonSize?: 'large' | 'medium' | 'small';
  captureChangeElementName?: string;
  children: React.ReactNode;
  debug?: boolean;
  defaultValues?: Record<string, any>;
  error?: any;
  formStyle?: { [key: string]: number | string };
  fullWidth?: boolean;
  hasActionButtons?: boolean;
  id: string;
  isActionButtonsAbsolute?: boolean;
  mode?: keyof ValidationMode;
  onCancel?: (value?: any) => void;
  onChange?: (value: any) => void;
  onSubmit?: (value: any) => void;
  saveOnly?: boolean;
  schema?: z.Schema<any, any>;
  stackStyle?: { [key: string]: number | string };
  values?: any;
}

export const Form = ({
  actionButtonsLeft,
  buttonProps,
  buttonSize = 'medium',
  captureChangeElementName,
  children,
  debug,
  defaultValues,
  error,
  formStyle,
  fullWidth,
  hasActionButtons,
  id,
  isActionButtonsAbsolute,
  mode,
  onCancel,
  onChange,
  onSubmit,
  saveOnly,
  schema,
  stackStyle = { marginTop: 2 },
  values,
}: FormProps) => {
  const resolver = (schema && zodResolver(schema)) || undefined;
  const methods = useForm({
    defaultValues,
    mode,
    resolver,
    values,
  });

  const {
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitted, isSubmitting },
    getValues,
    reset,
    setError,
  } = methods;

  const handleSubmit = methods.handleSubmit;

  const handleChange = () => {
    onChange && onChange(getValues());
  };

  const handleOnChangeCapture = (e: React.FormEvent<HTMLFormElement>) => {
    if (!captureChangeElementName) {
      return null;
    }

    methods.setValue(
      captureChangeElementName,
      (e.currentTarget.elements.namedItem(captureChangeElementName) as HTMLInputElement)?.value,
    );
  };

  React.useEffect(() => {
    if (debug)
      console.log('debug :>> ', {
        errors,
        isDirty,
        isSubmitSuccessful,
        isSubmitted,
        isSubmitting,
        reset,
        values: getValues(),
      });
    if (isSubmitSuccessful && error?.message === undefined && !isSubmitting && isSubmitted) {
      reset(defaultValues ?? methods.getValues());
    }
  }, [
    isSubmitSuccessful,
    reset,
    defaultValues,
    methods,
    error?.message,
    isSubmitted,
    isSubmitting,
    debug,
    isDirty,
    setError,
    getValues,
    errors,
  ]);

  React.useEffect(() => {
    if (error?.message) {
      setError(error?.name, {
        message: error?.message,
        type: error?.type,
      });
      enqueueSnackbar(error?.message, {
        autoHideDuration: 3000,
        variant: 'error',
      });
    }
  }, [setError, error?.message, error?.name, error?.type]);

  return (
    <FormProvider {...methods}>
      <form
        className="ITP-form"
        id={id}
        noValidate
        onChange={handleChange}
        onChangeCapture={handleOnChangeCapture}
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: fullWidth ? '100%' : '', ...formStyle }}
      >
        {children}
        {hasActionButtons && isDirty && (
          <Stack
            direction="row"
            gap={2}
            justifyContent={actionButtonsLeft ? 'start' : 'end'}
            position={isActionButtonsAbsolute ? 'absolute' : undefined}
            right={isActionButtonsAbsolute ? theme.spacing(2) : undefined}
            sx={stackStyle}
          >
            {!saveOnly && (
              <ButtonTextSecondary
                onClick={() => {
                  reset(defaultValues);
                  if (typeof onCancel === 'function') onCancel(defaultValues);
                }}
                aria-label={`Cancel ${id}`}
                size={buttonSize}
                {...buttonProps}
              >
                Cancel
              </ButtonTextSecondary>
            )}
            <ButtonPrimary
              aria-label={`Submit ${id}`}
              size={buttonSize}
              type="submit"
              {...buttonProps}
            >
              Save
            </ButtonPrimary>
          </Stack>
        )}
      </form>
    </FormProvider>
  );
};
