export const handleRouteChange = async (
  { as, toHref }: { as?: string; toHref: string },
  routePush?: (url: any, as?: any, options?: any) => Promise<boolean>,
) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (routePush) {
    await routePush(toHref, as ? as : toHref);
  }
};
