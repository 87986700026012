import { AvatarOwnProps, Box, CircularProgress, styled } from '@mui/material';

import { theme } from '@itp/component-library/lib/theme';

export const LoaderOverlay = styled(Box)(({ variant }: { variant: AvatarOwnProps['variant'] }) => ({
  alignItems: 'center',
  borderRadius: variant === 'circular' ? '50%' : theme.spacing(0.5),
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 1,
}));

export const StyledProgress = styled(CircularProgress)`
  color: white;
`;
