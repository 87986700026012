import * as React from 'react';

interface ImpersonateContextProps {
  children?: React.ReactNode;
}

export interface Impersonate {
  externalId?: null | string;
}

export interface ImpersonateContextValues {
  impersonate: Impersonate | null;
  setImpersonate: React.Dispatch<React.SetStateAction<Impersonate | null>>;
}

export const ImpersonateContext = React.createContext<ImpersonateContextValues>({
  impersonate: null,
  setImpersonate: () => {},
});

export const ImpersonateProvider: React.FC<ImpersonateContextProps> = ({ children }) => {
  const [impersonate, setImpersonate] = React.useState<Impersonate | null>(null);

  const value: ImpersonateContextValues = { impersonate, setImpersonate };

  return <ImpersonateContext.Provider value={value}>{children}</ImpersonateContext.Provider>;
};
