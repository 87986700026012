import { Box, styled } from '@mui/material';

export const FullWidthWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  bottom: 0,
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  maxWidth: theme.breakpoints.values.lg,
  paddingTop: theme.spacing(3),
  position: 'fixed',
  width: '-webkit-fill-available',
  zIndex: 0,
}));

export const FullWidthInnerWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 3),
}));
