import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  FormControlLabel,
  FormControlLabelProps,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';

type ModifiedFormProps = Omit<FormControlLabelProps, 'control'> & { control?: React.ReactNode };
interface SwitchProps {
  formProps?: ModifiedFormProps;
  switchProps?: MuiSwitchProps;
}

export const Switch: React.FC<SwitchProps> = ({ formProps, switchProps }) => {
  const {
    control,
    formState: { isSubmitting },
    register,
  } = useFormContext();

  const name = formProps?.name ?? 'switchInput';

  return (
    <FormControlLabel
      {...formProps}
      {...register(name)}
      control={
        <Controller
          render={({ field: { onChange, value } }) => (
            <MuiSwitch {...switchProps} checked={!!value} name={name} onChange={onChange} />
          )}
          control={control}
          name={name}
        />
      }
      disabled={isSubmitting}
      label={formProps?.label}
    />
  );
};
