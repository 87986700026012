import ky from 'ky';

import { useAuth0 } from '@auth0/auth0-react';

export const useKyFetcher = <T>() => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const fetcher = async (url, options: { headers?: Record<string, string> } = {}) => {
    let token: string;

    try {
      token = await getAccessTokenSilently({
        authorizationParams: {
          audience: options.headers?.audience,
          scope: options.headers?.scope,
        },
      });
    } catch (e) {
      if (e.error === 'consent_required') {
        token = await getAccessTokenWithPopup({
          authorizationParams: {
            audience: options.headers?.audience,
            scope: options.headers?.scope,
          },
        });
      } else {
        throw e;
      }
    }

    return ky(url, {
      ...options,
      headers: {
        Authorization: `Bearer ${token}`,
        ...options?.headers,
      },
    }).json<T>();
  };

  return fetcher;
};
