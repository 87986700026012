import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack } from '@mui/material';
import { lighten } from '@mui/system';

import {
  ButtonTextPrimary,
  ErrorAlert,
  IconButton,
  InfoAlert,
  Notification,
  SuccessAlert,
  theme,
  useNotifications,
  WarningAlert,
} from '@itp/component-library';

export const Notifications = () => {
  const { notifications, removeNotification } = useNotifications();

  const components = {
    error: ErrorAlert,
    info: InfoAlert,
    success: SuccessAlert,
    warning: WarningAlert,
  };

  const handleClose = (id: string) => {
    removeNotification(id);
  };

  if (!notifications.length) {
    return null;
  }

  return (
    <Box position="sticky" top={{ sm: '67px', xs: '59px' }} zIndex={100}>
      {notifications.map((notification: Notification, key) => {
        const Component = components[notification.type || 'success'];
        return (
          <Box
            alignItems="center"
            bgcolor={`${lighten(theme.palette[notification?.type || 'success'].light, 0.9)}`}
            display="flex"
            justifyContent="center"
            key={key}
          >
            <Component
              action={
                <Stack columnGap={1} flexDirection="row" justifyContent="center">
                  {notification.action?.onClick && (
                    <ButtonTextPrimary onClick={notification.action?.onClick}>
                      {notification.action?.label}
                    </ButtonTextPrimary>
                  )}
                  <IconButton
                    aria-label="Close notification"
                    color="primary"
                    onClick={() => handleClose(notification.id)}
                    size="small"
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              }
              sx={{ maxWidth: 'lg', px: 3, width: '100%' }}
            >
              {notification.content}
            </Component>
          </Box>
        );
      })}
    </Box>
  );
};
