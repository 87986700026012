import { Box, CircularProgress, circularProgressClasses, styled } from '@mui/material';

import { ProfileScoreCircularSize } from '@itp/component-library';

const BORDER = {
  large: '15px',
  medium: 100,
  small: '4px',
};

export const CircularProgressValue = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
}));

export const BorderCircularProgress = styled(CircularProgress)<{ size: ProfileScoreCircularSize }>(
  ({ size, theme, value }) => ({
    '&:before': {
      border: `${BORDER[size]} solid ${theme.palette.grey[300]}`,
      borderRadius: '50%',
      bottom: 0,
      content: "' '",
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: -1,
    },
    [`& .${circularProgressClasses.circle}`]: {
      backgroundColor: value === 100 ? theme.palette.success.main : theme.palette.primary.main,
      borderRadius: 15,
      strokeLinecap: 'round',
    },
    [`&.${circularProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
    },
    ...theme.profileScoreCircularSizes[size],
  }),
);
