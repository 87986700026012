import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { fetchAPI, requestAccessToken } from '@itp/component-library';

import { AUTH_CLIENT_ID, AUTH_DOMAIN } from '../../constants';
import { ORIGINAL_DOMAIN_AUTH_AUDIENCE } from './useAuthenticator';

const tokenOptions = {
  authorizationParams: {
    audience: ORIGINAL_DOMAIN_AUTH_AUDIENCE,
    scope: 'enroll',
  },
};

export function useEnrollment() {
  const auth = useAuth0();
  const [OOBCode, setOOBCode] = useState('');
  const method = 'POST';

  const enrollURL = `https://${AUTH_DOMAIN}/mfa/associate`;
  const confirmURL = `https://${AUTH_DOMAIN}/oauth/token`;

  const enrollWithSMS = async (phone_number: string) => {
    const mfa_token = await requestAccessToken(auth, tokenOptions);
    const res = await fetchAPI(
      {
        data: {
          authenticator_types: ['oob'],
          oob_channels: ['sms'],
          phone_number,
          mfa_token,
        },
        method,
        url: enrollURL,
      },
      { auth },
    );

    if (res.status === 200) {
      const { oob_code } = res.data;
      setOOBCode(oob_code);
    }
    return res;
  };

  const confirmSMS = async (binding_code: string) => {
    return fetchAPI(
      {
        data: {
          binding_code,
          client_id: AUTH_CLIENT_ID,
          grant_type: 'http://auth0.com/oauth/grant-type/mfa-oob',
          mfa_token: OOBCode,
        },
        method,
        url: confirmURL,
      },
      { auth },
    );
  };

  const enrollWithVoice = async (phone_number: string) => {
    const res = await fetchAPI(
      {
        data: {
          authenticator_types: ['oob'],
          oob_channels: ['voice'],
          phone_number,
        },
        method,
        url: enrollURL,
      },
      { auth },
    );

    if (res.status === 200) {
      const { oob_code } = res.data;
      setOOBCode(oob_code);
    }
    return res;
  };

  const confirmVoice = async (binding_code: string) => {
    return fetchAPI(
      {
        data: {
          binding_code,
          client_id: AUTH_CLIENT_ID,
          grant_type: 'http://auth0.com/oauth/grant-type/mfa-oob',
          mfa_token: OOBCode,
        },
        method,
        url: confirmURL,
      },
      { auth },
    );
  };

  const enrollWithOTP = async () => {
    const mfa_token = await requestAccessToken(auth, tokenOptions);
    return fetchAPI(
      {
        data: { authenticator_types: ['otp'], mfa_token },
        method,
        url: enrollURL,
        headers: {
          authorization: `Bearer ${mfa_token}`,
        },
      },
      { auth },
    );
  };

  const confirmOTP = async (otp: string) => {
    const mfa_token = await requestAccessToken(auth, tokenOptions);
    return fetchAPI(
      {
        data: {
          client_id: AUTH_CLIENT_ID,
          grant_type: 'http://auth0.com/oauth/grant-type/mfa-otp',
          mfa_token,
          otp,
        },
        method,
        url: confirmURL,
      },
      { auth },
    );
  };

  const enrollWithGuardian = async () => {
    return fetchAPI(
      {
        data: {
          authenticator_types: ['oob'],
          oob_channels: ['auth0'],
        },
        method,
        url: enrollURL,
      },
      { auth },
    );
  };

  return {
    confirmOTP,
    confirmSMS,
    confirmVoice,
    enrollWithGuardian,
    enrollWithOTP,
    enrollWithSMS,
    enrollWithVoice,
  };
}
