import * as React from 'react';

import MuiButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { push } from '@socialgouv/matomo-next';

export interface ButtonProps extends LoadingButtonProps {
  target?: React.HTMLAttributeAnchorTarget;
}

type IconButtonProps<D extends React.ElementType = 'button', P = {}> = MuiIconButtonProps<D, P> & {
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  target?: React.HTMLAttributeAnchorTarget;
  title?: string;
};

const track = (url) => {
  push(['trackEvent', 'button', `Click "${url}"`]);
};

export const ButtonPrimary: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  return (
    <MuiButton
      onClick={(e) => {
        track(props?.['aria-label'] || props?.children);
        onClick && onClick(e);
      }}
      color="primary"
      disableElevation
      size="large"
      variant="contained"
      {...props}
    />
  );
};

export const ButtonSecondary: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  return (
    <MuiButton
      onClick={(e) => {
        track(props?.['aria-label'] || props?.children);
        onClick && onClick(e);
      }}
      color="secondary"
      disableElevation
      size="large"
      variant="outlined"
      {...props}
    />
  );
};

export const ButtonTextPrimary: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  return (
    <MuiButton
      onClick={(e) => {
        track(props?.['aria-label'] || props?.children);
        onClick && onClick(e);
      }}
      color="primary"
      disableElevation
      size="large"
      variant="text"
      {...props}
    />
  );
};

export const ButtonTextSecondary: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  return (
    <MuiButton
      onClick={(e) => {
        track(props?.['aria-label'] || props?.children);
        onClick && onClick(e);
      }}
      color="secondary"
      disableElevation
      size="large"
      variant="text"
      {...props}
    />
  );
};

export const ButtonSquarePrimary: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  return (
    <MuiButton
      onClick={(e) => {
        track(props?.['aria-label'] || props?.children);
        onClick && onClick(e);
      }}
      color="primary"
      disableElevation
      size="large"
      variant="contained"
      {...props}
    />
  );
};

export const ButtonSquareError: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  return (
    <MuiButton
      onClick={(e) => {
        track(props?.['aria-label'] || props?.children);
        onClick && onClick(e);
      }}
      color="error"
      disableElevation
      size="large"
      variant="contained"
      {...props}
    />
  );
};

export const ButtonTextSquare: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  return (
    <MuiButton
      onClick={(e) => {
        track(props?.content);
        onClick && onClick(e);
      }}
      color="secondary"
      disableElevation
      size="large"
      variant="text"
      {...props}
    />
  );
};

export const IconButton: React.FC<IconButtonProps> = React.forwardRef<
  HTMLButtonElement,
  IconButtonProps
>(({ onClick, ...props }, ref) => {
  return (
    <MuiIconButton
      onClick={(e) => {
        track(props?.['aria-label'] || props?.title || 'icon button');
        onClick && onClick(e);
      }}
      ref={ref}
      {...props}
    />
  );
});

IconButton.displayName = 'IconButton';
