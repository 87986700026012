import * as React from 'react';

import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import { Box } from '@mui/material';

import { Avatar, BodyText, HeadingMedium } from '@itp/component-library';

interface PageHeaderExtendedProps {
  logo?: string;
  subTitle?: string;
  title?: string;
}

export const PageHeaderExtended: React.FC<PageHeaderExtendedProps> = ({
  logo,
  subTitle,
  title,
}) => (
  <Box display="flex" gap={3}>
    <Avatar alt={title} size="large" src={logo || undefined} variant="square">
      <CorporateFareOutlinedIcon />
    </Avatar>
    <Box display="flex" flexDirection="column" justifyContent="center">
      <HeadingMedium>{title}</HeadingMedium>
      <BodyText>{subTitle}</BodyText>
    </Box>
  </Box>
);
