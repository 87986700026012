import { useEffect, useState } from 'react';

import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import { QRCodeSVG } from 'qrcode.react';

import { Stack } from '@mui/material';

import {
  BodyText,
  BodyTextBold,
  ButtonPrimary,
  ButtonTextSecondary,
  Card,
  Form,
  HeadingLarge,
  TextField,
} from '@itp/component-library';
import { useEnrollment } from '@itp/myaccount';
import { useRouter } from 'next/router';
import { enqueueSnackbar } from 'notistack';
import { z } from 'zod';
import { SubmitHandler } from 'react-hook-form';
import { useAuthenticator } from '../../hooks/mfa';

const OtpSchema = z.object({
  binding_code: z.string().regex(/^\d{6}$/, { message: 'OTP must be a 6-digit numeric code' }),
});

export function OtpEnrollment() {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const searchParams = useSearchParams();
  const barcode_uri = searchParams.get('barcode_uri');
  const { deleteInActiveAuthenticators } = useAuthenticator();

  const { confirmOTP } = useEnrollment();

  function setIsEnrollingToFalse() {
    localStorage.setItem('isEnrolling', 'false');
  }

  useEffect(() => {
    window.addEventListener('beforeunload', setIsEnrollingToFalse);
    window.addEventListener('popstate', setIsEnrollingToFalse);

    return () => {
      window.removeEventListener('beforeunload', setIsEnrollingToFalse);
      window.removeEventListener('popstate', setIsEnrollingToFalse);
    };
  }, []);

  const cancelEnrollment = async () => {
    await deleteInActiveAuthenticators();
    router.push('/account');
  };

  const confirmOTPEnrollment: SubmitHandler<z.infer<typeof OtpSchema>> = async (e) => {
    setLoading(true);
    try {
      const result = await confirmOTP(e.binding_code);
      console.log('result :>> ', result);
      if (!result.error) {
        enqueueSnackbar('Success', { variant: 'success' });
        router.push('/account');
      } else {
        enqueueSnackbar(result?.error, { variant: 'error' });
      }
    } catch (error: any) {
      enqueueSnackbar(error, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack display="flex" justifyContent="center">
        <Card loading={!barcode_uri}>
          <Card.Header>
            <HeadingLarge>Secure Your Account</HeadingLarge>
          </Card.Header>
          <Card.Content>
            <BodyText>
              1. <BodyTextBold>Scan the QR code</BodyTextBold> with your preferred authenticator
              app, then <BodyTextBold>enter your generated One-time Password</BodyTextBold>.
            </BodyText>

            <Stack alignItems="center" display="flex" justifyContent="center" py={4}>
              {barcode_uri ? <QRCodeSVG level="Q" size={146} value={barcode_uri} /> : <></>}
            </Stack>

            <Stack alignSelf="center" margin="auto" maxWidth="300px">
              <Form
                debug={true}
                id="otp"
                schema={OtpSchema}
                values={{ binding_code: '' }}
                onSubmit={confirmOTPEnrollment}
              >
                <TextField
                  autoFocus
                  fullWidth
                  id="otp-input"
                  placeholder="XXX XXX"
                  name="binding_code"
                />

                <ButtonPrimary
                  loading={loading}
                  type="submit"
                  size="large"
                  sx={{ mt: 2, width: '100%' }}
                >
                  Continue
                </ButtonPrimary>

                <ButtonTextSecondary
                  type="reset"
                  onClick={cancelEnrollment}
                  sx={{ mt: 1, width: '100%' }}
                >
                  Cancel enrollment
                </ButtonTextSecondary>
              </Form>
            </Stack>
          </Card.Content>
        </Card>
        <Stack display="flex" flexDirection="row" justifyContent="center" mt={8}>
          <a
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            rel="noopener external noreferrer"
            target="_blank"
          >
            <Image
              alt="App Store"
              height={50}
              src="https://portal.iglu.net/media/app_store.svg"
              width={170}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            rel="noopener external noreferrer"
            target="_blank"
          >
            <Image
              alt="Play Store"
              height={50}
              src="https://portal.iglu.net/media/play_store.svg"
              width={170}
            />
          </a>
        </Stack>
      </Stack>
    </>
  );
}
