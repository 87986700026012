import React, { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@mui/material';

import { ButtonPrimary, Card, HeadingLarge } from '@itp/component-library';

export const HomePage = () => {
  const { isAuthenticated } = useAuth0();
  const router = useRouter();

  useEffect(() => {
    if (isAuthenticated) router.push('/account');
  }, [isAuthenticated, router]);

  return (
    <Card>
      <Card.Header>
        <HeadingLarge>Account details</HeadingLarge>
      </Card.Header>
      <Card.Content>
        <Stack mb={2}>Login to iglu single sign-on system or reset your password</Stack>
        <ButtonPrimary>Start here</ButtonPrimary>
      </Card.Content>
    </Card>
  );
};
