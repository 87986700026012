// @ts-nocheck
import { useEffect, useState } from 'react';

import axios, { AxiosRequestConfig } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { Auth0ContextInterface, GetTokenSilentlyOptions, User } from '@auth0/auth0-react';

import { SearchParams } from './types';

type Options = AxiosRequestConfig & {
  searchParams?: SearchParams;
};
type ExtraOptions = { auth?: Auth0ContextInterface<User>; showErrors?: boolean };

export const requestAccessToken = async (
  auth: Auth0ContextInterface<User>,
  options: GetTokenSilentlyOptions,
) => {
  try {
    return await auth?.getAccessTokenSilently(options);
  } catch (e) {
    try {
      return await auth?.getAccessTokenSilently(options);
    } catch (e: unknown) {
      return false;
    }
  }
};

export async function fetchAPI<T>(options: Options = {}, extra?: ExtraOptions) {
  const { catchCb, data, method = 'GET' } = options;

  const headers = {
    ...options.headers,
    ...(extra?.auth && { authorization: `Bearer ${await requestAccessToken(extra.auth)}` }),
  };

  const config = {
    ...options,
    data,
    headers: { ...headers },
    method,
    params: {
      ...options.params,
      ...options.searchParams,
    },
  };

  return axios(config)
    .then(({ data }) => {
      if (typeof extra?.setData === 'function') {
        extra.setData(data);
      }
      return data as Promise<T>;
    })
    .catch((err) => {
      if (catchCb) catchCb(err.response.data);
      if (extra?.showErrors)
        enqueueSnackbar(err.response.statusText || err.response?.data?.message.substring(0, 300), {
          autoHideDuration: 3000,
          variant: 'error',
        });
      return err;
    }) as Promise<any | T>;
}

export function useFetch<T>(options?: Options, extra?: ExtraOptions) {
  const [data, setData] = useState<T>();

  useEffect(() => {
    fetchAPI<T>(options, { ...extra, setData });
  }, [extra, options]);

  return { data } as { data: null | T };
}
