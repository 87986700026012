import React from 'react';

import {
  LinkBubbleMenu,
  LinkBubbleMenuHandler,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonHorizontalRule,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuControlsContainer,
  MenuSelectHeading,
  RichTextEditorProvider,
} from 'mui-tiptap';

import { FormHelperText } from '@mui/material';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { theme } from '@itp/component-library/lib/theme';

import { StyledRichTextField } from './richTextField.styles';

type MuiTipTapProps = {
  error?: string;
  onChange: (...event: string[]) => void;
  value: string;
};

export const MuiTipTap: React.FC<MuiTipTapProps> = ({ error, onChange, value }) => {
  const CustomLinkExtension = Link.extend({
    inclusive: false,
  });

  const editor = useEditor({
    content: value,
    extensions: [
      CustomLinkExtension.configure({
        autolink: true,
        linkOnPaste: true,
        openOnClick: false,
      }),
      StarterKit,
      Placeholder.configure({ placeholder: 'Enter your text here...' }),
      LinkBubbleMenuHandler,
    ],
    onUpdate({ editor }) {
      const value = editor.getHTML() === '<p></p>' ? '' : editor.getHTML();
      onChange(value);
    },
  });

  React.useEffect(() => {
    editor?.commands.setContent(value);
  }, [value, editor]);

  return (
    <RichTextEditorProvider editor={editor}>
      <LinkBubbleMenu />
      <StyledRichTextField
        controls={
          <MenuControlsContainer>
            <MenuSelectHeading />
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuButtonOrderedList />
            <MenuButtonBulletedList />
            <MenuButtonEditLink />
            <MenuButtonBlockquote />
            <MenuButtonHorizontalRule />
          </MenuControlsContainer>
        }
        sx={{ '& > div:last-child': { border: error ? '1px solid #F00622' : '' } }}
        theme={theme}
      />
      <FormHelperText error sx={{ margin: '-20px 0 0 16px' }}>
        {error}
      </FormHelperText>
    </RichTextEditorProvider>
  );
};
