import { Box, styled } from '@mui/material';

export const StyledBox = styled(Box)({
  '&:focus': {
    outline: 'none',
  },
  background: 'white',
  boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px',
  left: '50%',
  minWidth: 400,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});
