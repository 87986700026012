import * as React from 'react';

import Grid, { GridProps } from '@mui/material/Grid';

export type AsideProps = {
  children?: React.ReactNode;
} & GridProps;

export const Aside: React.FC<AsideProps> = ({ children, ...props }) => {
  return (
    <Grid component="aside" container flexDirection="column" gap={3} item md={3} {...props}>
      {children}
    </Grid>
  );
};
