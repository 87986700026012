import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { ButtonPrimary, Link } from '@itp/component-library';

import { NotFound, NotFound404 } from './pageNotFound.styles';

type PageNotFoundProps = {
  linkText?: string;
  linkUrl?: string;
  message?: string;
  status?: string;
  title?: string;
};

export const PageNotFound: React.FC<PageNotFoundProps> = ({
  linkText = 'Home page',
  linkUrl = '/',
  message = 'The page you are looking for might have been removed had its name changed or is temporarily unavailable.',
  status = '404',
  title = 'We are sorry, page not found!',
}) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ height: 'calc(100vh - 350px)', width: '100%' }}
    >
      <Stack
        alignItems="center"
        gap={2}
        justifyContent="center"
        maxWidth={{ sm: '60%', xs: '90%' }}
        position={'relative'}
        textAlign={'center'}
      >
        <NotFound>
          <NotFound404>{status}</NotFound404>
        </NotFound>
        <Typography fontWeight={700} letterSpacing={1} variant="h4">
          {title}
        </Typography>
        <Typography fontWeight={400} variant="headingMedium">
          {message}
        </Typography>
        <Box>
          <ButtonPrimary href={linkUrl} LinkComponent={Link}>
            {linkText}
          </ButtonPrimary>
        </Box>
      </Stack>
    </Stack>
  );
};
