import React from 'react';
import { useCurrentRefinements, UseCurrentRefinementsProps } from 'react-instantsearch';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Paper, Stack } from '@mui/material';

import { BodyText, BodyTextBold, IconButton, theme } from '@itp/component-library';

export const SearchCurrentRefinements = (props: UseCurrentRefinementsProps) => {
  const { items, refine } = useCurrentRefinements(props);

  return (
    <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
      <>
        {items.map((item) => (
          <Paper
            elevation={0}
            key={[item.indexName, item.label].join('/')}
            sx={{ backgroundColor: theme.palette.grey[200], px: 2 }}
            variant="outlined"
          >
            <BodyTextBold mr={1} textTransform="capitalize">
              {item.label}:
            </BodyTextBold>
            {item.refinements.map((refinement) => (
              <Box component="span" key={refinement.label}>
                <BodyText textTransform="capitalize">{refinement.label}</BodyText>
                <IconButton
                  onClick={(event) => {
                    if (isModifierClick(event)) {
                      return;
                    }

                    refine(refinement);
                  }}
                  aria-label="Remove filter"
                  size="small"
                >
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            ))}
          </Paper>
        ))}
      </>
    </Stack>
  );
};

function isModifierClick(event: React.MouseEvent) {
  const isMiddleClick = event.button === 1;

  return Boolean(isMiddleClick || event.altKey || event.ctrlKey || event.metaKey || event.shiftKey);
}
