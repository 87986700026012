import * as React from 'react';

import { Typography, TypographyProps } from '@mui/material';

export interface HeadingProps extends TypographyProps {
  component?: React.ElementType;
}

export const HeadingLarge: React.FC<HeadingProps> = ({ children, ...props }) => {
  return (
    <Typography {...props} variant="headingLarge">
      {children}
    </Typography>
  );
};

export const HeadingMedium: React.FC<HeadingProps> = ({ children, ...props }) => {
  return (
    <Typography {...props} variant="headingMedium">
      {children}
    </Typography>
  );
};

export const HeadingSmall: React.FC<HeadingProps> = ({ children, ...props }) => {
  return (
    <Typography {...props} variant="headingSmall">
      {children}
    </Typography>
  );
};
