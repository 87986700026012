import { useEffect } from 'react';

import QRCode, { QRCodeSVG } from 'qrcode.react';
import { useRouter } from 'next/router';
import { useSearchParams } from 'next/navigation';
import { Stack } from '@mui/material';
import { BodyText, ButtonPrimary, ButtonTextSecondary, Card } from '@itp/component-library';
import Image from 'next/image';
import { useAuthenticator } from '../../hooks/mfa';

export function PushEnrollment() {
  const history = useRouter();
  const searchParams = useSearchParams();

  const oob_code = searchParams.get('oob_code');
  const barcode_uri = searchParams.get('barcode_uri');
  const base_url = searchParams.get('base_url');
  const _url = `${barcode_uri}&base_url=${base_url}`;
  const { deleteInActiveAuthenticators } = useAuthenticator();

  useEffect(() => {
    function setIsEnrollingToFalse(e: any) {
      localStorage.setItem('isEnrolling', 'false');
    }

    window.addEventListener('beforeunload', setIsEnrollingToFalse);

    return () => window.removeEventListener('beforeunload', setIsEnrollingToFalse);
  }, []);

  useEffect(() => {
    function setIsEnrollingToFalse(e: any) {
      localStorage.setItem('isEnrolling', 'false');
    }

    window.addEventListener('popstate', setIsEnrollingToFalse);

    return () => window.removeEventListener('popstate', setIsEnrollingToFalse);
  }, []);

  const cancelEnrollment = async () => {
    await deleteInActiveAuthenticators();
    history.push('/security');
  };

  const backToSetting = () => {
    localStorage.setItem('isEnrolling', 'false');
    history.push('/security');
  };

  if (!barcode_uri || !oob_code) {
    return <div>Loading</div>;
  }
  return (
    <Stack>
      <Card>
        <Card.Header>Secure Your Account</Card.Header>
        <Card.Content>
          <BodyText>
            In order to continue, install the Auth0 Guardian app via the app store from your mobile
            device.
          </BodyText>
          <BodyText>
            Then scan the QR code with your Auth0 Guardian app and enter your generated One-time
            Password.
          </BodyText>

          <Stack alignItems="center" display="flex" justifyContent="center" py={4}>
            {barcode_uri ? <QRCodeSVG level="Q" size={146} value={_url} /> : <></>}
          </Stack>

          <ButtonPrimary type="submit" size="large" sx={{ mt: 2, width: '100%' }}>
            Continue
          </ButtonPrimary>

          <ButtonTextSecondary
            type="reset"
            onClick={cancelEnrollment}
            sx={{ mt: 1, width: '100%' }}
          >
            Cancel enrollment
          </ButtonTextSecondary>
        </Card.Content>
      </Card>
      <Stack display="flex" flexDirection="row" justifyContent="center" mt={8}>
        <a
          href="https://apps.apple.com/us/app/google-authenticator/id388497605"
          rel="noopener external noreferrer"
          target="_blank"
        >
          <Image
            alt="App Store"
            height={50}
            src="https://portal.iglu.net/media/app_store.svg"
            width={170}
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          rel="noopener external noreferrer"
          target="_blank"
        >
          <Image
            alt="Play Store"
            height={50}
            src="https://portal.iglu.net/media/play_store.svg"
            width={170}
          />
        </a>
      </Stack>
    </Stack>
  );
}
