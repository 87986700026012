import * as React from 'react';

import { AlertProps } from '@mui/material';

import { StyledAlert } from './alert.styles';

export const SuccessAlert: React.FC<AlertProps> = ({ children, ...props }) => {
  return (
    <StyledAlert severity="success" {...props}>
      {children}
    </StyledAlert>
  );
};
export const WarningAlert: React.FC<AlertProps> = ({ children, ...props }) => {
  return (
    <StyledAlert severity="warning" {...props}>
      {children}
    </StyledAlert>
  );
};
export const ErrorAlert: React.FC<AlertProps> = ({ children, ...props }) => {
  return (
    <StyledAlert severity="error" sx={{ color: 'error.main' }} {...props}>
      {children}
    </StyledAlert>
  );
};
export const InfoAlert: React.FC<AlertProps> = ({ children, ...props }) => {
  return (
    <StyledAlert severity="info" {...props}>
      {children}
    </StyledAlert>
  );
};
