import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material';

type AutocompleteProps = { name: string } & MuiAutocompleteProps<
  any,
  boolean,
  boolean,
  boolean,
  'div'
>;

export const Autocomplete: React.FC<AutocompleteProps> = ({
  name = 'autocomplete',
  onChange,
  ...props
}) => {
  const {
    control,
    formState: { defaultValues },
  } = useFormContext();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Controller
      {...props}
      render={({ field: { onChange: fieldOnChange, ...field } }) => {
        return (
          <MuiAutocomplete
            {...props}
            {...field}
            onChange={(e, newValue, reason) => {
              onChange ? onChange(e, newValue, reason) : fieldOnChange(newValue);
            }}
            defaultValue={defaultValues?.[name]}
            onKeyDown={handleKeyDown}
          />
        );
      }}
      control={control}
      defaultValue={defaultValues?.[name]}
      name={name}
    />
  );
};
