import * as React from 'react';

import { CardActions, CardActionsProps } from '@mui/material';

export interface ActionsProps extends CardActionsProps {
  children: React.ReactNode;
}

export const Actions: React.FC<ActionsProps> = ({ children, ...props }) => {
  return <CardActions {...props}>{children}</CardActions>;
};
