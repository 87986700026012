import { isObject } from '@itp/component-library/lib/utils/isObject';

export enum MainPaths {
  ACCOUNT = '/account',
  HOMEPAGE = '/',
}

export const PathNames = {
  ...MainPaths,
};

export enum ROUTES {
  ACCOUNT = 'ACCOUNT',
  HOMEPAGE = 'HOMEPAGE',
}

export const getRoutingPath = (pathKey: keyof typeof PathNames): string => {
  return PathNames[pathKey] || PathNames[ROUTES.HOMEPAGE];
};

/**
 * Generates a routing path with optional query parameters.
 *
 * @param {keyof typeof PathNames} pathKey - The key to lookup the base path from the `PathNames` object.
 * @param {...(string | number | Record<string, any>)} args - The path segments and optional query parameters.
 *   - Path segments are added to the path.
 *   - If the last argument is an object, it is treated as query parameters.
 * @returns {string} The constructed URL path with optional query string.
 */
export const generateRoutingPath = (
  pathKey: keyof typeof PathNames,
  ...args: (number | Record<string, any> | string)[]
): string => {
  const path = PathNames[pathKey] || PathNames[ROUTES.HOMEPAGE];
  const lastArg = args[args.length - 1];
  const queryObject = isObject(lastArg) ? (args.pop() as Record<string, any>) : {};

  const filteredQueryObject = Object.fromEntries(
    Object.entries(queryObject).filter(([, value]) => !!value),
  );

  const tail = args.length ? '/' + args.join('/') : '';
  const queryString = Object.keys(filteredQueryObject).length
    ? '?' + new URLSearchParams(filteredQueryObject).toString()
    : '';

  return path + tail + queryString;
};
