import React from 'react';

import { Stack } from '@mui/material';

import { Link } from '@itp/component-library';

import { FullWidthInnerWrapper, FullWidthWrapper } from './footer.styles';

export const Footer = () => {
  return (
    <FullWidthWrapper>
      <FullWidthInnerWrapper>
        <Stack alignItems="center" direction="row" mb={2} spacing={4}>
          <Stack gap={1} flexDirection={'row'} whiteSpace="nowrap">
            <span>&copy; Copyright</span>
            <Link href="https://iglu.net" sx={{ ml: 1, textDecoration: 'none' }} target="_blank">
              Iglu Network Ltd.
            </Link>
          </Stack>
        </Stack>
      </FullWidthInnerWrapper>
    </FullWidthWrapper>
  );
};
