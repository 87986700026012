import React from 'react';

import { Box } from '@mui/material';

import { Link } from '@itp/component-library';

export interface BreadCrumbLink {
  name: string;
  url: string;
}

export interface BreadCrumbProps {
  active?: string;
  links?: BreadCrumbLink[];
}

export const BreadCrumb: React.FC<BreadCrumbProps> = ({ active, links }) => {
  if (!links && !active) {
    return null;
  }

  return (
    <Box>
      {links.map(
        (link, index) =>
          link?.url && (
            <React.Fragment key={index}>
              <Link href={link?.url} key={index} sx={{ textDecoration: 'none' }}>
                {link?.name}
              </Link>
              <Box component={'span'} mx={2}>
                /
              </Box>
            </React.Fragment>
          ),
      )}
      {active}
    </Box>
  );
};
