import React from 'react';

import { GridToolbarQuickFilterProps as MUIGridToolbarQuickFilterProps } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';

import { StyledGridToolbarQuickFilter } from '../dataTable.styles';

type GridToolbarQuickFilterProps = MUIGridToolbarQuickFilterProps & {
  apiRef: React.MutableRefObject<GridApiCommunity>;
};

export const GridToolbarQuickFilter: React.FC<GridToolbarQuickFilterProps> = ({
  apiRef,
  value,
  ...props
}) => {
  React.useEffect(() => {
    if (value) {
      apiRef.current.setQuickFilterValues([value]);
    }
  }, [apiRef, value]);

  return <StyledGridToolbarQuickFilter size="small" variant="outlined" {...props} />;
};
