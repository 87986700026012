import { createTheme } from '@mui/material/styles';

import { theme as sharedTheme } from '@itp/component-library';

export const theme = createTheme(sharedTheme, {
  cardStyles: {
    border: 'none',
    borderRadius: '12px',
    boxShadow: '4px 4px 18px 0px rgba(147, 147, 147, 0.10)',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          scroll-behavior: smooth;
        }
      `,
    },
  },
});
