import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';

import { theme } from '@itp/component-library';

type TextFieldProps = { errorMessage?: any } & MuiTextFieldProps;

export const TextField: React.FC<TextFieldProps> = ({
  errorMessage = '',
  name = 'textField',
  size = 'medium',
  ...props
}) => {
  const {
    control,
    formState: { defaultValues, errors, isSubmitting },
    register,
  } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <MuiTextField
          {...props}
          {...field}
          {...register(name)}
          helperText={
            (errors[name as string]?.message as string) || errorMessage || props.helperText
          }
          disabled={isSubmitting || props.disabled}
          error={!!errors[name as string]?.message || !!errorMessage}
          fullWidth
          size={size}
          sx={{ ...props?.sx, borderColor: theme.palette.secondary.main }}
        />
      )}
      control={control}
      defaultValue={defaultValues?.[name]}
      name={name}
    />
  );
};
