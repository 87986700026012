import * as React from 'react';

import { Box, Avatar as MuiAvatar, AvatarProps as MuiAvatarProps } from '@mui/material';

import { AvatarSize, theme } from '@itp/component-library/lib/theme';

import { LoaderOverlay, StyledProgress } from './avatar.styles';

interface AvatarProps extends MuiAvatarProps {
  children?: React.ReactNode;
  loading?: boolean;
  size?: AvatarSize;
}

const MAX_RETRIES = 5;

const CIRCULAR_PROGRESS_SIZING = {
  large: 48,
  medium: 24,
  small: 16,
};

export const Avatar: React.FC<AvatarProps> = ({
  children,
  loading: propsLoading,
  size = 'large',
  src = '',
  ...props
}) => {
  const [url, setUrl] = React.useState('');
  const retryCountRef = React.useRef(0);
  const [loading, setLoading] = React.useState(false);

  const errorHandler = () => {
    setLoading(true);
    if (retryCountRef.current < MAX_RETRIES) {
      const delay = Math.pow(2, retryCountRef.current) * 1000;
      setTimeout(() => {
        setUrl(src + '?retry=' + Date.now());
        retryCountRef.current += 1;
      }, delay);
    } else {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (src) {
      setLoading(true);
      setUrl(src);
    }
    retryCountRef.current = 0;
  }, [src]);

  return (
    <Box display="inline-block" height="fit-content" position="relative">
      <MuiAvatar
        src={url}
        variant="circular"
        {...props}
        imgProps={{
          crossOrigin: 'anonymous',
          onError: errorHandler,
          onLoad: () => {
            setLoading(false);
          },
        }}
        sx={{ ...theme?.avatarSizes?.[size], aspectRatio: '1' }}
      >
        {children}
      </MuiAvatar>

      {(loading || propsLoading) && (
        <LoaderOverlay
          bgcolor="grey.300"
          sx={{ aspectRatio: '1' }}
          {...theme?.avatarSizes?.[size]}
          variant={props.variant || 'circular'}
        >
          <StyledProgress size={CIRCULAR_PROGRESS_SIZING[size]} />
        </LoaderOverlay>
      )}
    </Box>
  );
};
