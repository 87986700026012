import * as React from 'react';

import { Backdrop, Fade, Modal as MuiModal, ModalProps as MuiModalprops } from '@mui/material';

import { StyledBox } from './modal.style';

type ModalProps = {
  children: React.ReactNode;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
} & MuiModalprops;

export const Modal: React.FC<ModalProps> = ({ children, onClose, open, ...props }) => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <>
      <MuiModal
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        aria-describedby="transition-modal-description"
        aria-labelledby="transition-modal-title"
        closeAfterTransition
        onClose={handleClose}
        open={open}
        slots={{ backdrop: Backdrop }}
        {...props}
      >
        <Fade in={open}>
          <StyledBox>{children}</StyledBox>
        </Fade>
      </MuiModal>
    </>
  );
};
