import { useState } from 'react';

import { enqueueSnackbar } from 'notistack';

import { fetchAPI } from '@itp/component-library';

import { AUTH_CLIENT_ID, AUTH_DOMAIN } from '../../constants';

export function usePassword() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const requestPasswordReset = async (email: string) => {
    setLoading(true);

    const response = await fetchAPI({
      baseURL: 'https://' + AUTH_DOMAIN,
      data: {
        client_id: AUTH_CLIENT_ID,
        connection: 'Username-Password-Authentication',
        email,
      },
      method: 'POST',
      url: '/dbconnections/change_password',
    });

    setLoading(false);

    if (typeof response === 'string') {
      setSuccess(true);
      enqueueSnackbar('Password reset email sent successfully.', { variant: 'success' });
    } else {
      setError(true);
      if (response.status === 429) {
        enqueueSnackbar('Too many requests. Please try again later.', { variant: 'error' });
      } else {
        enqueueSnackbar('Failed to send password reset email.', { variant: 'error' });
      }
    }
  };

  return {
    error,
    loading,
    requestPasswordReset,
    success,
  };
}
