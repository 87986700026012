import { AppProps } from 'next/app';

import createCache from '@emotion/cache';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { StyledEngineProvider } from '@mui/material/styles';

import { AppSkeleton, MainPageLayout } from '@itp/myaccount';

type AppPropsWithLayout = AppProps & {
  emotionCache?: EmotionCache;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const cache = createCache({
    key: 'css',
    prepend: true,
  });

  const getLayout =
    (Component as { getLayout?: (page: React.ReactElement) => React.ReactElement }).getLayout ||
    ((page: React.ReactElement) => page);

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={cache}>
        <AppSkeleton>
          <MainPageLayout>{getLayout(<Component {...pageProps} />)}</MainPageLayout>
        </AppSkeleton>
      </CacheProvider>
    </StyledEngineProvider>
  );
}
