import * as React from 'react';

import Grid, { GridProps } from '@mui/material/Grid';

export type MainContentProps = {
  children?: React.ReactNode;
} & GridProps;

export const MainContent: React.FC<MainContentProps> = ({ children, ...props }) => {
  return (
    <Grid component="main" container gap={3} item xs {...props}>
      {children}
    </Grid>
  );
};
