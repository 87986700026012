import * as React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { ButtonPrimary, ButtonSecondary } from '@itp/component-library';

type Props = {
  message?: string;
  title?: string;
};

export const useConfirmDialog = ({ message, title }: Props) => {
  const [promise, setPromise] = React.useState(null);

  const confirm = (): Promise<boolean> =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const ConfirmationDialog = () => (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      open={promise !== null}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ textAlign: 'center', whiteSpace: 'pre-line' }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <ButtonPrimary
          aria-label="Confirm dialog"
          autoFocus
          color="error"
          data-testid="confirm-button"
          onClick={handleConfirm}
        >
          Confirm
        </ButtonPrimary>
        <ButtonSecondary
          aria-label="Cancel confirm dialog"
          data-testid="cancel-button"
          onClick={handleCancel}
        >
          Cancel
        </ButtonSecondary>
      </DialogActions>
    </Dialog>
  );

  return { confirm, ConfirmationDialog };
};
