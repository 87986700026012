import * as React from 'react';

import { Backdrop, Box, CircularProgress, CardProps as MuiCardProps } from '@mui/material';

import { theme } from '@itp/component-library/lib/theme';

import { ActionArea, ActionAreaProps } from './actionArea';
import { Actions, ActionsProps } from './actions';
import { StyledCard } from './card.styles';
import { Content, ContentProps } from './content';
import { Header, HeaderProps } from './header';

export interface CardProps extends MuiCardProps {
  children: React.ReactNode;
  loading?: boolean;
  noShadow?: boolean;
  selected?: boolean;
}

interface Composition {
  ActionArea: React.FC<ActionAreaProps>;
  Actions: React.FC<ActionsProps>;
  Content: React.FC<ContentProps>;
  Header: React.FC<HeaderProps>;
}

export const Card: React.FC<CardProps> & Composition = ({
  children,
  loading,
  noShadow = false,
  ...rest
}) => {
  return (
    <StyledCard {...rest} loading={loading} noShadow={noShadow} variant="outlined">
      <Box height="100%" position="relative">
        <Backdrop
          sx={{
            background: 'rgba(255,255,255,0.5)',
            color: theme.palette.primary.main,
            position: 'absolute',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={!!loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {children}
      </Box>
    </StyledCard>
  );
};

Card.Content = Content;
Card.Header = Header;
Card.Actions = Actions;
Card.ActionArea = ActionArea;
