import React from 'react';

import { Stack } from '@mui/material';

import { Link, LogoIcon } from '@itp/component-library';

import { FullWidthInnerWrapper, FullWidthWrapper } from './footer.styles';

export const Footer = () => {
  return (
    <FullWidthWrapper>
      <FullWidthInnerWrapper>
        <LogoIcon width="97px" />
        <Stack
          alignItems={{ xs: 'center' }}
          flexDirection={{ sm: 'row' }}
          justifyContent={{ sm: 'space-between' }}
          rowGap={2}
        >
          &copy; Copyright 2023, Iglu Network Ltd.
          <Stack columnGap={5} flexDirection="row">
            <Link
              href="https://iglu.net/privacy-policy/"
              sx={{ textDecoration: 'none' }}
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              href="https://iglu.net/terms-and-conditions/"
              sx={{ textDecoration: 'none' }}
              target="_blank"
            >
              Terms & Condition
            </Link>
            <Link href="https://iglu.net/csr/" sx={{ textDecoration: 'none' }} target="_blank">
              CSR
            </Link>
          </Stack>
        </Stack>
      </FullWidthInnerWrapper>
    </FullWidthWrapper>
  );
};
