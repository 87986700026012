import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputLabel } from '@mui/material';

import { MuiTipTap } from './muiTipTap';

type RichtextFieldProps = {
  label?: string;
  name?: string;
  required?: boolean;
};

export const RichTextField: React.FC<RichtextFieldProps> = ({
  label,
  name = 'richTextField',
  required,
}) => {
  const {
    control,
    formState: { defaultValues, errors },
  } = useFormContext();

  return (
    <>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <Controller
        render={({ field: { onChange, value } }) => (
          <MuiTipTap
            error={errors[name as string]?.message as string}
            onChange={onChange}
            value={defaultValues?.[name]}
          />
        )}
        control={control}
        defaultValue={defaultValues?.[name]}
        name={name}
      />
    </>
  );
};
