import React from 'react';

import { useRouter } from 'next/router';

import { useAuth0 } from '@auth0/auth0-react';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import { ListItemText, MenuList, Stack, SvgIconTypeMap, useMediaQuery } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import {
  BodyTextBold,
  handleRouteChange,
  LogoIcon,
  LogoIconCompact,
  theme,
} from '@itp/component-library';

import {
  HomeLink,
  StyledDrawer,
  StyledListItemIcon,
  StyledMenuItem,
} from './asideNavigation.styles';
import { menuItems } from './menuItems';
import { ProfileMenu } from './profileMenu';

export interface MenuItem {
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
  label: string;
  link?: string;
}

export const AsideNavigation = ({ handleDrawerToggle, open }) => {
  const router = useRouter();
  const { isAuthenticated } = useAuth0();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const handleMenuClick = (link?: string) => {
    handleDrawerToggle();
    link && handleRouteChange({ toHref: link }, router?.push);
  };

  const generatedMenuItems = () =>
    menuItems().map((menu, index) => {
      const isSelected = menu.link === router.pathname;

      return (
        <StyledMenuItem
          key={index}
          onClick={() => handleMenuClick(menu?.link)}
          selected={isSelected}
        >
          <StyledListItemIcon>
            <menu.icon />
          </StyledListItemIcon>
          <ListItemText sx={{ [theme.breakpoints.between('sm', 'md')]: { display: 'none' } }}>
            <BodyTextBold>{menu.label}</BodyTextBold>
          </ListItemText>
        </StyledMenuItem>
      );
    });

  return (
    <StyledDrawer
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          border: 'none',
          boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.10)',
          zIndex: 1000,
        },
      }}
      anchor="left"
      onClose={handleDrawerToggle}
      open={isMobile ? open : true}
      variant={isMobile ? 'temporary' : 'permanent'}
    >
      <HomeLink href="/">{isTablet ? <LogoIconCompact /> : <LogoIcon />}</HomeLink>
      <Stack direction="column" height="100%" justifyContent="space-between">
        {isAuthenticated ? <MenuList>{generatedMenuItems()}</MenuList> : <></>}
        <MenuList sx={{ py: 0 }}>
          <ProfileMenu />
        </MenuList>
      </Stack>
    </StyledDrawer>
  );
};
