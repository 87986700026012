import * as React from 'react';

import Grid, { GridProps } from '@mui/material/Grid';

export type PageContainerProps = {
  children?: React.ReactNode;
} & GridProps;

export const PageContainer: React.FC<PageContainerProps> = ({ children, ...props }) => {
  return (
    <Grid
      {...props}
      container
      flexWrap="nowrap"
      gap={3}
      marginX={'auto'}
      mt={3}
      paddingX={{ sm: 3, xs: 1 }}
      sx={{ maxWidth: 'lg', ...props?.sx }}
    >
      {children}
    </Grid>
  );
};
