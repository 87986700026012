import React from 'react';

import { Box, LinearProgressProps, Stack } from '@mui/material';

import { BodyText } from '@itp/component-library';

import { BorderLinearProgress } from './profileScoreLinear.styles';

export type LinearProgressSize = 'large' | 'medium' | 'small';

type ProfileScoreLinearProps = {
  label?: string;
  size?: LinearProgressSize;
} & LinearProgressProps;

export const ProfileScoreLinear: React.FC<ProfileScoreLinearProps> = ({
  label = 'Profile completeness',
  size = 'small',
  value = 0,
  ...props
}) => {
  return (
    <>
      <Stack alignItems="center" direction="row" gap={1}>
        <BodyText noWrap>{label}:</BodyText>
        <BorderLinearProgress size={size} value={value} variant="determinate" {...props} />
        <Box>
          <BodyText color="text.secondary" variant="body2">{`${Math.round(value)}%`}</BodyText>
        </Box>
      </Stack>
    </>
  );
};
