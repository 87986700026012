import * as React from 'react';

import { CardActionAreaProps, Typography } from '@mui/material';

import { StyledCardActionArea } from './card.styles';

export interface ActionAreaProps extends CardActionAreaProps {
  children: React.ReactNode;
  href?: string;
}

export const ActionArea: React.FC<ActionAreaProps> = ({ children, ...props }) => {
  return (
    <StyledCardActionArea {...props}>
      <Typography component="p" py={2} textAlign="center" variant="headingSmall">
        {children}
      </Typography>
    </StyledCardActionArea>
  );
};
