import React from 'react';

import { Box, CircularProgressProps } from '@mui/material';

import { ProfileScoreCircularSize } from '@itp/component-library';

import { BodyTextBold } from '../typography';
import { BorderCircularProgress, CircularProgressValue } from './profileScoreCircular.styles';

type ProfileScoreCircularProps = {
  showValue?: boolean;
  size?: ProfileScoreCircularSize;
} & CircularProgressProps;

export const ProfileScoreCircular: React.FC<ProfileScoreCircularProps> = ({
  showValue,
  size = 'small',
  ...props
}) => {
  return (
    <Box sx={{ display: 'inline-flex', position: 'relative' }}>
      <BorderCircularProgress
        size={size}
        variant="determinate"
        {...props}
        color={props.value === 100 ? 'success' : 'info'}
      />
      {showValue && (
        <CircularProgressValue>
          <BodyTextBold component="div" variant="caption">{`${Math.round(
            props.value,
          )}%`}</BodyTextBold>
        </CircularProgressValue>
      )}
    </Box>
  );
};
