import * as React from 'react';

import { Typography, TypographyProps } from '@mui/material';

export interface BodyProps extends TypographyProps {
  component?: React.ElementType;
}

export const BodyText: React.FC<BodyProps> = ({ children, ...props }) => {
  return (
    <Typography {...props} variant="body">
      {children}
    </Typography>
  );
};

export const BodyTextBold: React.FC<BodyProps> = ({ children, ...props }) => {
  return (
    <Typography {...props} variant="bodyBold">
      {children}
    </Typography>
  );
};

export const BodyTextSmall: React.FC<BodyProps> = ({ children, ...props }) => {
  return (
    <Typography {...props} variant="bodySmall">
      {children}
    </Typography>
  );
};

export const BodyTextSmallBold: React.FC<BodyProps> = ({ children, ...props }) => {
  return (
    <Typography {...props} variant="bodySmallBold">
      {children}
    </Typography>
  );
};

export const BodyTextNumbers: React.FC<BodyProps> = ({ children, ...props }) => {
  return (
    <Typography {...props} variant="bodyNumbers">
      {children}
    </Typography>
  );
};
