import * as React from 'react';

import { SnackbarProvider } from 'notistack';

import { Auth0Provider } from '@auth0/auth0-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { NotificationsProvider } from '@itp/component-library';
import {
  AUTH_CLIENT_ID,
  AUTH_DOMAIN,
  ORIGINAL_DOMAIN,
  RouteProtection,
  SharedHeaders,
  theme,
  UserProvider,
} from '@itp/myaccount';

import 'dayjs/locale/en-gb';

type AppSkeletonProps = {
  children?: React.ReactNode;
};

export const AppSkeleton: React.FC<AppSkeletonProps> = ({ children }) => {
  const onRedirectCallback = (appState: any) => {
    const path = appState?.targetUrl || window.location.pathname;
    window.history.replaceState({}, document.title, `${window.location.origin}#${path}`);
  };

  return (
    <Auth0Provider
      authorizationParams={{
        audience: `https://${ORIGINAL_DOMAIN}/mfa/`,
        redirect_uri: typeof window !== 'undefined' ? window.location.origin : undefined,
        scope:
          'openid profile email offline_access read:authenticators remove:authenticators enroll',
        useRefreshTokensFallback: false,
      }}
      cacheLocation="localstorage"
      clientId={AUTH_CLIENT_ID}
      domain={AUTH_DOMAIN}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      <RouteProtection>
        <UserProvider>
          <NotificationsProvider>
            <ThemeProvider theme={theme}>
              <SnackbarProvider>
                <SharedHeaders />
                <CssBaseline />
                {children}
              </SnackbarProvider>
            </ThemeProvider>
          </NotificationsProvider>
        </UserProvider>
      </RouteProtection>
    </Auth0Provider>
  );
};
