import { RichTextField } from 'mui-tiptap';

import { styled } from '@mui/material';

export const StyledRichTextField = styled(RichTextField)(({ theme }) => ({
  '& .MuiCollapse-root': {
    backgroundColor: theme.palette.common.white,
    border: 'none',
  },
}));
