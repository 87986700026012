import { LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LinearProgressSize } from './profileScoreLinear';

const SIZE = {
  large: 300,
  medium: 200,
  small: 100,
};

export const BorderLinearProgress = styled(LinearProgress)<{ size: LinearProgressSize }>(
  ({ size, theme, value }) => ({
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: value === 100 ? theme.palette.success.main : theme.palette.primary.main,
      borderRadius: 5,
    },
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
    },
    borderRadius: 5,
    height: 10,
    width: SIZE[size],
  }),
);
