import * as React from 'react';

import { useRouter } from 'next/router';

import { Box, Tab as MuiTab, Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';

export interface TabsData {
  content: React.ReactNode;
  isDisabled?: boolean;
  label: string;
}

interface TabsProps extends MuiTabsProps {
  tabsData: TabsData[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, index, value, ...other } = props;

  return (
    <div
      aria-labelledby={`tab-${index}`}
      hidden={value !== index}
      id={`tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    'aria-controls': `tabpanel-${index}`,
    id: `tab-${index}`,
  };
}

function addTabToUrl(activeTab: string) {
  const url = new URL(window.location.href);
  url.searchParams.set('tab', activeTab);
  window.history.pushState(
    { ...window.history.state, as: url.toString(), url: url.toString() },
    '',
    url.toString(),
  );
}

export const Tabs: React.FC<TabsProps> = ({ tabsData, ...props }) => {
  const router = useRouter();

  const [value, setValue] = React.useState(props?.value || 0);

  React.useEffect(() => {
    if (router?.query?.tab) {
      setValue(() => Number(router.query.tab));
    }
  }, [router.query.tab]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    addTabToUrl(newValue.toString());
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <MuiTabs onChange={handleChange} value={value}>
        {tabsData.map((tab, index) => (
          <MuiTab
            disabled={tab.isDisabled}
            key={tab.label}
            label={tab.label}
            {...a11yProps(index)}
          />
        ))}
      </MuiTabs>
      {tabsData.map((tab, index) => (
        <TabPanel index={index} key={tab.label} value={value}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};
