import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@mui/material';

import { ButtonPrimary, Card, HeadingLarge } from '@itp/component-library';
import { usePassword } from '@itp/myaccount';

export const Password = () => {
  const { loading, requestPasswordReset } = usePassword();
  const { user } = useAuth0();
  return (
    <Card>
      <Card.Header>
        <HeadingLarge>Change password</HeadingLarge>
      </Card.Header>
      <Card.Content
        sx={{
          alignItems: {
            xs: 'flex-start',
            sm: 'center',
          },
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },

          display: 'flex',
          justifyContent: 'space-between',
          pt: 0,
        }}
      >
        <Stack
          sx={{
            mb: {
              xs: 1,
              sm: 0,
            },
          }}
          display="flex"
          flexDirection="row"
        >
          Click button to reset your password
        </Stack>
        <ButtonPrimary
          onClick={() => {
            if (user?.email) requestPasswordReset(user?.email);
          }}
          loading={loading}
        >
          Reset password
        </ButtonPrimary>
      </Card.Content>
    </Card>
  );
};
