import { withAuthenticationRequired } from '@auth0/auth0-react';

type RouteProtectionProps = {
  children?: React.ReactNode;
};

const Component: React.FC<RouteProtectionProps> = ({ children }) => {
  return <>{children}</>;
};

export const RouteProtection = withAuthenticationRequired(Component);
