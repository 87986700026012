import { Grid, Paper, styled } from '@mui/material';

export const PageHeaderWrapper = styled(Grid)({
  background: '#fff',

  width: '100%',
});

export const PageHeaderPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'compact',
})<{ compact?: boolean }>(({ compact, theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: compact ? '50px' : '130px',
  margin: '0 auto',
  maxWidth: theme.breakpoints.values.lg,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  width: '100%',
}));
