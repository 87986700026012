import { styled } from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';

export const StyledGridToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(0, 1),
  right: 0,
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, 2),
    position: 'absolute',
  },
  top: theme.spacing(1),
}));

export const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: 20,
    fontSize: '12px',
  },
  [theme.breakpoints.only('xs')]: {
    width: '100%',
  },
}));
