import React from 'react';

import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import { Stack } from '@mui/material';

import { ButtonSecondary } from '@itp/component-library';

export const GridToolbarActions = ({ addButtonText = 'Add', onAdd }) => {
  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      gap={1}
      justifyContent="flex-end"
      mr={3}
      width="100%"
    >
      <ButtonSecondary
        aria-label="Add to Data Table"
        data-testid="add-to-dataTable-btn"
        onClick={onAdd}
        size="small"
        startIcon={<PlaylistAddOutlinedIcon />}
      >
        {addButtonText}
      </ButtonSecondary>
    </Stack>
  );
};
