import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';

type RadioButtonProps = {
  label?: string;
  name: string;
  options: {
    label: string;
    value: string;
  }[];
  radioGroupProps?: RadioGroupProps;
} & FormControlProps;

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  name,
  options,
  radioGroupProps,
  ...props
}) => {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const isError = !!errors[name]?.message || props.error;
  const helperText = (errors[name]?.message as string) || props['aria-describedby'];

  return (
    <FormControl
      disabled={props.disabled || isSubmitting}
      error={isError}
      fullWidth
      required={props.required}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        render={({ field }) => (
          <RadioGroup {...field} aria-labelledby={name} id={props.id} {...radioGroupProps}>
            {options.map((option) => (
              <FormControlLabel
                control={<Radio />}
                key={option.value}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioGroup>
        )}
        control={control}
        name={name}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
