import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';

type ModifiedFormProps = Omit<FormControlLabelProps, 'control'> & { control?: React.ReactNode };

interface CheckboxProps {
  checkboxProps?: MuiCheckboxProps & { 'data-testid'?: string };
  formProps?: ModifiedFormProps;
}

export const Checkbox: React.FC<CheckboxProps> = ({ checkboxProps, formProps }) => {
  const {
    control,
    formState: { defaultValues, errors },
  } = useFormContext();

  const name = formProps?.name ?? 'checkboxInput';

  return (
    <Box>
      <FormControlLabel
        {...formProps}
        control={
          <Controller
            render={({ field: { onChange, value } }) => (
              <MuiCheckbox
                {...checkboxProps}
                onChange={(e, checked) => {
                  return onChange(
                    checked ? (e.target?.value === 'on' ? true : e.target?.value) : false,
                  );
                }}
                checked={!!value}
                defaultValue={defaultValues?.[name]}
                name={name}
                size="small"
              />
            )}
            control={control}
            defaultValue={defaultValues?.[name]}
            name={name}
          />
        }
        sx={{
          cursor: checkboxProps?.disabled ? 'not-allowed' : 'pointer',
          opacity: checkboxProps?.disabled ? 0.5 : 1,
        }}
        label={formProps?.label}
      />
      {errors && (
        <FormHelperText error sx={{ ml: 2, mt: -1 }}>
          {errors[name as string]?.message as string}
        </FormHelperText>
      )}
    </Box>
  );
};
