import { useState } from 'react';

import { ButtonPrimary } from '@itp/component-library';
import { useEnrollment } from '@itp/myaccount';

import { AddAuthenticatorsItem } from './AddAuthenticatorsItem';
import { useRouter } from 'next/router';

export function AddAuthenticators() {
  const {
    confirmVoice,
    enrollWithGuardian,
    enrollWithOTP: enrollOTP,
    enrollWithSMS,
  } = useEnrollment();
  const [toggleAddAuthenticator, setToggleAddAuthenticator] = useState(false);
  const [selectedAuthenticatorType, setSelectedAuthenticatorType] = useState('otp');
  const router = useRouter();

  const handleOnSelectAuthenticatorType = (option: any) => {
    const { name } = option;
    switch (name) {
      case 'OTP':
        setSelectedAuthenticatorType('otp');
        break;
      case 'SMS':
        setSelectedAuthenticatorType('sms');
        break;
      case 'VOICE':
        setSelectedAuthenticatorType('voice');
        break;
      case 'PUSH':
        setSelectedAuthenticatorType('push');
        break;
      default:
        break;
    }
  };

  const enrollWithAuthenticator = async (type: string) => {
    localStorage.setItem('isEnrolling', 'true');
    switch (type) {
      case 'otp':
        const { barcode_uri } = await enrollOTP();
        router.push(`/mfa-otp-enrollment?barcode_uri=${barcode_uri}`);
        break;
      case 'sms':
        await enrollWithSMS('');
        router.push('/mfa-sms-enrollment');
        break;
      case 'voice':
        await confirmVoice('');
        router.push('/mfa-voice-enrollment');
        break;
      case 'push':
        await enrollWithGuardian();
        router.push('/mfa-push-enrollment');
        break;
      default:
        break;
    }
  };

  const handleContinueWithSelectedAuthenticatorTypeOnClick = async () => {
    await enrollWithAuthenticator(selectedAuthenticatorType);
  };

  return (
    <div className="flex mt-4">
      {toggleAddAuthenticator ? (
        <>
          <AddAuthenticatorsItem onSelect={handleOnSelectAuthenticatorType} />
          <ButtonPrimary
            onClick={handleContinueWithSelectedAuthenticatorTypeOnClick}
            sx={{ mt: 1 }}
          >
            Continue
          </ButtonPrimary>
        </>
      ) : (
        <ButtonPrimary onClick={() => setToggleAddAuthenticator(true)} sx={{ marginTop: 2 }}>
          Add authenticator
        </ButtonPrimary>
      )}
    </div>
  );
}
