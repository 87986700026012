import { Card, CardActionArea, styled } from '@mui/material';

import { CardProps } from './card';

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'noShadow' && prop !== 'loading',
})<CardProps>(({ loading, noShadow, selected, theme }) => ({
  borderColor: selected ? theme.palette.primary.main : undefined,
  height: 'fit-content',
  opacity: loading ? 0.5 : 1,
  transition: 'opacity 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  width: '100%',
  ...(noShadow ? undefined : (theme?.cardStyles as object)),
}));

export const StyledCardActionArea = styled(CardActionArea)`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;
