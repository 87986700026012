import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  MenuItemProps,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  SelectChangeEvent,
} from '@mui/material';

type Option = MenuItemProps;

type MenuItemPropsExtend = { value: any } & MenuItemProps;

type SelectProps = {
  error?: boolean;
  options: Option[];
} & FormControlProps &
  MuiSelectProps;

const MenuItemComponent: React.FC<MenuItemPropsExtend> = (props) => {
  const { children, value, ...rest } = props;

  return (
    <MenuItem value={value} {...rest}>
      {children}
    </MenuItem>
  );
};

export const Select: React.FC<SelectProps> = ({ name = 'selectField', options, ...props }) => {
  const {
    formState: { defaultValues, errors, isSubmitting },
    getValues,
    register,
  } = useFormContext();

  const formValue = getValues(name);
  const [selectedValue, setSelectedValue] = React.useState(formValue);

  React.useEffect(() => {
    setSelectedValue(formValue);
  }, [formValue]);

  const selectRegister = register(name);

  const isError = !!errors[name as string]?.message || props.error;

  const helperText = (errors[name as string]?.message as string) || props['aria-describedby'];

  const handleChange = (e: SelectChangeEvent<any>) => {
    selectRegister.onChange(e);
    setSelectedValue(e.target.value);
  };

  const isValidValue = options?.some(
    (option) =>
      option.key.toString() === selectedValue?.toString() ||
      option.key.toString() === props?.value?.toString(),
  );

  return (
    <FormControl
      defaultValue={defaultValues?.[name]}
      disabled={props.disabled || isSubmitting}
      error={isError}
      fullWidth
      required={props.required}
      sx={props.sx}
    >
      {props.label && (
        <InputLabel id={props.labelId} size={props.size as 'normal' | 'small'}>
          {props.label}
        </InputLabel>
      )}
      <MuiSelect
        {...props}
        {...register(name)}
        MenuProps={{
          sx: {
            maxHeight: '400px',
          },
        }}
        defaultValue={defaultValues?.[name]}
        id={props.id}
        label={props.label}
        labelId={props.labelId}
        name={name}
        onChange={props.onChange || handleChange}
        size={props.size || 'medium'}
        value={isValidValue ? selectedValue || props?.value : ''}
      >
        {options.map((select) => (
          <MenuItemComponent
            key={select.key}
            selected={select.key === selectedValue}
            value={(select.key as string) || ''}
          >
            {select.value}
          </MenuItemComponent>
        ))}
      </MuiSelect>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : <></>}
    </FormControl>
  );
};
