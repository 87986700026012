import { Dispatch, SetStateAction, useState } from 'react';

import { enqueueSnackbar } from 'notistack';

import { Stack } from '@mui/material';

import { BodyText, ButtonSecondary, theme, useConfirmDialog } from '@itp/component-library';
import { useAuthenticator } from '@itp/myaccount';

type AuthenticatorItemProps = {
  authenticator: any;
  setAuthenticators: Dispatch<SetStateAction<any[]>>;
};

export function AuthenticatorItem({ authenticator, setAuthenticators }: AuthenticatorItemProps) {
  const { authenticator_type: type, id, name, oob_channel: channel } = authenticator;
  const { deleteAuthenticator, getAuthenticators } = useAuthenticator();

  const { confirm, ConfirmationDialog } = useConfirmDialog({
    message: `This will permanently remove this authenticator.`,
    title: 'Remove authenticator',
  });

  const [loading, setLoading] = useState(false);

  const handleOnDeleteAuth = async () => {
    const hasConfirmed = await confirm();
    if (hasConfirmed) await deleteAuth();
  };

  const deleteAuth = async () => {
    setLoading(true);
    try {
      await deleteAuthenticator(id);
      setAuthenticators(await getAuthenticators());
    } catch (error: any) {
      enqueueSnackbar(error, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const renderType = (key: string) => {
    switch (key) {
      case 'otp':
        return 'One-time Password';
      case 'oob':
        return 'Out-of-Band';
      default:
        return 'Recovery code';
    }
  };

  const renderChannel = (key: string) => {
    switch (key) {
      case 'auth0':
        return 'Push';
      case 'sms':
        return 'SMS';
      case 'voice':
        return 'Call';
      default:
        return '';
    }
  };

  return (
    <>
      {id ? (
        <Stack
          alignItems="center"
          borderBottom={1}
          borderColor={theme.palette.grey[300]}
          display="flex"
          flexDirection="row"
          key={id}
          width="100%"
        >
          <Stack alignItems="center" display="flex" flexDirection="row" flexGrow={1} py={3}>
            <img alt="totp" className="mr-2" src="https://portal.iglu.net/media/totp.svg" />
            <BodyText ml={2}>
              {name ? name + ' - ' : ''}
              {renderType(type)}
              {channel ? ' ' + renderChannel(channel) : ''}
            </BodyText>
          </Stack>
          <ButtonSecondary disabled={loading} onClick={handleOnDeleteAuth} type="button">
            Remove
          </ButtonSecondary>
        </Stack>
      ) : (
        <></>
      )}
      <ConfirmationDialog />
    </>
  );
}
