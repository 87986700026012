import * as React from 'react';

import { GridProps } from '@mui/material/Grid';

import { BodyText } from '@itp/component-library';

import { PageHeaderPaper, PageHeaderWrapper } from './pageHeader.styles';

export type PageHeaderProps = {
  children?: React.ReactNode;
  compact?: boolean;
  component?: React.ElementType;
} & GridProps;

export const PageHeader: React.FC<PageHeaderProps> = ({ children, compact, ...props }) => {
  return (
    <PageHeaderWrapper component="header" container item sm={12} xs {...props}>
      <PageHeaderPaper compact={compact} elevation={0} square>
        {compact ? <BodyText>{children}</BodyText> : children}
      </PageHeaderPaper>
    </PageHeaderWrapper>
  );
};
