import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';

type ModifiedFormProps = Omit<FormControlLabelProps, 'control'> & { control?: React.ReactNode };

interface CheckboxOptions {
  checkboxProps?: CheckboxProps & { 'data-testid'?: string };
  formProps?: ModifiedFormProps;
}

type CheckboxGroupProps = {
  label?: JSX.Element | string;
  name: string;
  options: CheckboxOptions[];
  row?: boolean;
};

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  label,
  name,
  options,
  row = false,
}) => {
  const {
    control,
    formState: { defaultValues, errors },
  } = useFormContext();

  return (
    <>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup row={row}>
        {options.map((option, index) => (
          <Box key={index}>
            <FormControlLabel
              {...option.formProps}
              control={
                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Checkbox
                        {...option.checkboxProps}
                        onChange={(e, checked) => {
                          return onChange(
                            checked ? (e.target?.value === 'on' ? true : e.target?.value) : false,
                          );
                        }}
                        checked={!!value}
                        defaultValue={defaultValues?.[name]?.[option.formProps.value as string]}
                        name={`${name}.${option.formProps.value}`}
                        size="small"
                      />
                    );
                  }}
                  control={control}
                  defaultValue={defaultValues?.[name]?.[option.formProps.value as string]}
                  name={`${name}.${option.formProps.value}`}
                />
              }
              sx={{
                cursor: option.checkboxProps?.disabled ? 'not-allowed' : 'pointer',
                opacity: option.checkboxProps?.disabled ? 0.5 : 1,
              }}
              label={option.formProps?.label}
            />
          </Box>
        ))}
      </FormGroup>
      {errors && (
        <FormHelperText error sx={{ ml: 2, mt: 0 }}>
          {errors[name as string]?.message as string}
        </FormHelperText>
      )}
    </>
  );
};
