import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers';

dayjs.extend(utc);
dayjs.extend(timezone);

type DatePickerProps = {
  name?: string;
  required?: boolean;
} & MuiDatePickerProps<Date>;

export const DatePicker: React.FC<DatePickerProps> = ({ name = 'datePicker', sx, ...props }) => {
  const {
    control,
    formState: { defaultValues, errors },
  } = useFormContext();

  return (
    <Controller
      {...props}
      render={({ field: { onChange, ...field } }) => (
        <MuiDatePicker
          {...field}
          slotProps={{
            textField: {
              error: !!errors[name as string]?.message,
              helperText: errors[name as string]?.message as string,
              label: props?.label,
              required: props?.required,
            },
          }}
          defaultValue={defaultValues?.[name]}
          onChange={onChange}
          sx={sx}
        />
      )}
      control={control}
      defaultValue={dayjs.tz(defaultValues?.[name], 'America/New_York')}
      name={name}
    />
  );
};
