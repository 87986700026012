import { Box, InputBase, styled, Toolbar } from '@mui/material';

import { Link } from '@itp/component-library/lib/components/core/link';

export const Search = styled('div')(({ theme }) => ({
  '&&& .MuiInputBase-root': {
    height: '40px',
    paddingTop: '4px',
  },
  backgroundColor: theme.palette.grey[200],
  borderRadius: '20px',
  height: theme.spacing(5),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    width: '426px',
  },
  width: '100%',
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  padding: theme.spacing(0, 2),
  pointerEvents: 'none',
  position: 'absolute',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    [theme.breakpoints.up('md')]: {
      width: '426px',
    },
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  color: 'inherit',
}));

export const TopBarWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.background.paper,
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  position: 'sticky',
  top: '0',
  width: '100%',
  zIndex: '100',
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  paddingRight: theme.spacing(3),
}));

export const HomeLink = styled(Link)(({ theme }) => ({
  '& svg': {
    marginTop: theme.spacing(1),
    width: '97px',
  },
  justifySelf: 'center',
}));
