import * as React from 'react';

import Grid, { GridProps } from '@mui/material/Grid';

import { Aside, AsideProps } from './aside/aside';
import { MainContent, MainContentProps } from './mainContent/mainContent';
import { PageContainer, PageContainerProps } from './pageContainer/pageContainer';
import { PageHeader, PageHeaderProps } from './pageHeader/pageHeader';

type Composition = {
  Aside: React.FC<AsideProps>;
  MainContent: React.FC<MainContentProps>;
  PageContainer: React.FC<PageContainerProps>;
  PageHeader: React.FC<PageHeaderProps>;
};

type NestedPageLayoutProps = {
  children?: React.ReactNode;
} & GridProps;

export const NestedPageLayout: React.FC<NestedPageLayoutProps> & Composition = ({
  children,
  ...props
}) => {
  return (
    <Grid container {...props}>
      {children}
    </Grid>
  );
};

NestedPageLayout.Aside = Aside;
NestedPageLayout.MainContent = MainContent;
NestedPageLayout.PageContainer = PageContainer;
NestedPageLayout.PageHeader = PageHeader;
