import React from 'react';

import { Box, Stack } from '@mui/material';

import { NestedPageLayout } from '@itp/component-library';
import { AsideNavigation, Footer, TopBar } from '@itp/myaccount';

type MainPageLayoutProps = {
  children?: React.ReactNode;
};

export const MainPageLayout: React.FC<MainPageLayoutProps> = ({ children }) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <TopBar handleDrawerToggle={handleDrawerToggle} />
      <Stack direction="row">
        <AsideNavigation handleDrawerToggle={handleDrawerToggle} open={open} />
        <Box
          sx={{
            margin: '0 auto',
            minHeight: 'calc(100vh - 280px)',
            pb: 3,
            width: '100%',
          }}
        >
          <NestedPageLayout flexDirection={'column'}>
            <NestedPageLayout.PageContainer sx={{ flexDirection: 'column', mt: 1 }}>
              <NestedPageLayout.MainContent mb={9} zIndex={1}>
                {children}
              </NestedPageLayout.MainContent>
              <Footer />
            </NestedPageLayout.PageContainer>
          </NestedPageLayout>
        </Box>
      </Stack>
    </>
  );
};
