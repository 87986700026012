import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { getRoutingPath, ROUTES } from '@itp/myaccount';

import { MenuItem } from './asideNavigation';

export const menuItems = (): MenuItem[] => [
  {
    icon: AdminPanelSettingsIcon,
    label: 'Account',
    link: getRoutingPath(ROUTES.ACCOUNT),
  },
];
